import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import {AuthForm} from "./AuthForm";
import {RegisterHeader} from "./RegisterHeader";
import {GuardianForm} from "./GuardianForm";
import {EmployeeForm} from "./EmployeeForm";
import {AddressForm} from "./AddressForm";
import {useParams} from 'react-router-dom'
import * as actions from '../../store/actions'
import {Spinner} from "../shared/Spinner";

const UserForm = ({
                      employee,
                      guardian,
                      onEmployeeFetch,
                      onGuardianFetch,
                      onEmployeeUpdate,
                      onGuardianUpdate,
                      onCreateUser,
                      onAccountSave,
                      onDetailsClear
                  }) => {

    const {getAccessTokenSilently} = useAuth0()
    const {account_type, account_id} = useParams()

    const isDetails = !!account_id;

    const [can_edit, set_editable] = useState(!isDetails || false)
    const [is_employee, set_is_employee] = useState(account_type === 'employee')
    const [is_guardian, set_is_guardian] = useState(account_type === 'guardian' || !isDetails)
    let initial_data;

    useEffect(() => {
        getAccessTokenSilently().then(token => {
            switch (account_type) {
                case 'employee':
                    onEmployeeFetch(token, account_id);
                    return;
                case 'guardian':
                    onGuardianFetch(token, account_id);
                    return;
                default:
                    onDetailsClear()
            }
        })
    }, [])

    const handleFormSubmit = (event) => {
        event.preventDefault();
        getAccessTokenSilently().then(token => {
            isDetails
                ? onAccountSave(token, account_id, account_type, is_employee ? employee : guardian)
                : onCreateUser(token, is_employee ? employee : guardian)
        })
    }

    const handleAuthFormChange = (name, value) => {
        if (!can_edit) return;
        if (name === 'type') {
            set_is_employee(value === 'employee')
            set_is_guardian(value === 'guardian')
        }
        is_employee ? onEmployeeUpdate(name, value, true) : onGuardianUpdate(name, value, true)
    }

    const handleGuardianFormChange = (name, value) => {
        if (!can_edit) return;
        onGuardianUpdate(name, value)
    }

    const handleEmployeeFormChange = (name, value) => {
        if (!can_edit) return;
        onEmployeeUpdate(name, value)
    }

    const handleAddressFormChange = (name, value) => {
        if (!can_edit) return;
        onEmployeeUpdate(name, value);
        onGuardianUpdate(name, value);
    }

    const handleDisabledCheck = (newDisabledValue) => {
        set_editable(newDisabledValue)
    }

    is_employee ? initial_data = employee : initial_data = guardian

    if (!initial_data)
        return <Spinner/>

    return (
        <React.Fragment>
            <RegisterHeader onFieldChange={newVal => handleDisabledCheck(newVal)}
                            can_edit={can_edit}
                            displayEditable={isDetails}/>
            <Grid container spacing={6} component={"form"}>
                <AuthForm onFieldChange={(name, value) => handleAuthFormChange(name, value)}
                          first_name={initial_data.auth_user.first_name}
                          last_name={initial_data.auth_user.last_name}
                          password={initial_data.auth_user.password}
                          confirm_password={initial_data.auth_user.confirm_password}
                          email={initial_data.auth_user.email}
                          positions={initial_data.positions}
                          user_type={is_employee ? 'employee' : 'guardian'}
                          isDetails={isDetails}
                          isGuardian={is_guardian}
                          can_edit={can_edit}
                />
                <AddressForm onFieldChange={(name, value) => handleAddressFormChange(name, value)}
                             address_1={initial_data.address_1}
                             address_2={initial_data.address_2}
                             address_3={initial_data.address_3}
                             address_4={initial_data.address_4}
                             city={initial_data.city}
                             country={initial_data.country}
                             postal={initial_data.postal}
                             can_edit={can_edit}
                />
                {
                    is_guardian
                        ? <GuardianForm onFieldChange={(name, value) => handleGuardianFormChange(name, value)}
                                        mobile_phone={initial_data.mobile_phone}
                                        mobile_phone2={initial_data.mobile_phone2}
                                        home_phone={initial_data.home_phone}
                                        work_phone={initial_data.work_phone}
                                        work_hours_from={initial_data.work_hours_from}
                                        work_hours_to={initial_data.work_hours_to}
                                        work_weekends={initial_data.work_weekends}
                                        can_edit={can_edit}
                        />
                        : is_employee
                        ? <EmployeeForm onFieldChange={(name, value) => handleEmployeeFormChange(name, value)}
                                        mobile_phone={initial_data.mobile_phone}
                                        position={initial_data.position}
                                        hire_date={initial_data.hire_date}
                                        department={initial_data.department}
                                        work_hours={initial_data.work_hours}
                                        employee_type={initial_data.employee_type}
                                        date_of_birth={initial_data.date_of_birth}
                                        gender={initial_data.gender}
                                        can_edit={can_edit}
                        />
                        : null
                }
                <Grid item xs={12}>
                    <Button color={'secondary'}
                            size={'large'}
                            variant={'contained'}
                            onClick={handleFormSubmit}
                    >
                        {isDetails ? 'Save' : 'Create'} Account
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

const mapStateToProps = store => {
    return {
        employee: store.accounts.employee_details,
        guardian: store.accounts.guardian_details
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreateUser: (token, user_type) => dispatch(actions.registerUser(token, user_type)),
        onAccountSave: (token, id, type, user) => dispatch(actions.saveAccount(token, id, type, user)),
        onEmployeeFetch: (token, id) => dispatch(actions.fetchEmployee(token, id)),
        onGuardianFetch: (token, id) => dispatch(actions.fetchGuardian(token, id)),
        onEmployeeUpdate: (name, value, isAuth = false) => dispatch(actions.updateEmployeeDetails(name, value, isAuth)),
        onGuardianUpdate: (name, value, isAuth = false) => dispatch(actions.updateGuardianDetails(name, value, isAuth)),
        onDetailsClear: () => dispatch(actions.clearAccountDetails())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
