import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {EmployeeType} from "./Constants";

export const EmployeeTypeSelector = ({
                                         onFieldChange,
                                         initialEmployeeTypeState,
                                         can_edit
                                     }) => {

    const handleChange = event => {
        if (!can_edit) return;
        const {name, value} = event.target
        onFieldChange(name, value);
    }

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Employee type</FormLabel>
            <RadioGroup name="employee_type"
                        value={initialEmployeeTypeState}
                        onChange={handleChange} row>
                {
                    Object.entries(EmployeeType).map(([k, v]) =>
                        <FormControlLabel value={v}
                                          key={k}
                                          control={<Radio color={'primary'}/>}
                                          label={v}/>
                    )
                }
            </RadioGroup>
        </FormControl>
    )
}
