import React, {useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {Gender} from "./Constants";

export const GenderSelect = ({
                                 onSelect,
                                 initialGender,
                                 can_edit
                             }) => {
    const [state, setState] = useState({
        gender: initialGender
    });

    const handleChange = event => {
        if (!can_edit) return;
        const {name, value} = event.target
        setState({gender: value});
        onSelect(name, value);
    }

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup name="gender" value={state.gender} onChange={handleChange} row>
                {
                    Object.entries(Gender).map(([k, v]) =>
                        <FormControlLabel value={v}
                                          key={k}
                                          control={<Radio color={'primary'}/>}
                                          label={v}/>
                    )
                }
            </RadioGroup>
        </FormControl>
    )
}
