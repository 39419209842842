import {
    RESIDENTS_CLEAR_MEDICAL_REPORT,
    RESIDENTS_CLEAR_RESIDENT_DETAILS,
    RESIDENTS_FETCH_EMERGENCY_CONTACT,
    RESIDENTS_FETCH_EMERGENCY_CONTACTS,
    RESIDENTS_FETCH_FAMILY_MEMBER,
    RESIDENTS_FETCH_FAMILY_MEMBERS,
    RESIDENTS_FETCH_MEDICAL_REPORT,
    RESIDENTS_FETCH_MEDICAL_REPORTS,
    RESIDENTS_FETCH_RESIDENT,
    RESIDENTS_FETCH_RESIDENTS,
    RESIDENTS_UPDATE_MEDICAL_REPORT,
    RESIDENTS_UPDATE_RESIDENT_DETAILS
} from '../actions/action.types'
import {DefaultMedialReport, DefaultResident} from "../../components/shared/Constants";

const initialState = {}

export const residentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESIDENTS_FETCH_EMERGENCY_CONTACT:
            return {
                ...state,
                emergency_contact_details: action.payload
            }
        case RESIDENTS_FETCH_EMERGENCY_CONTACTS:
            return {
                ...state,
                emergency_contacts: action.payload
            }
        case RESIDENTS_FETCH_FAMILY_MEMBER:
            return {
                ...state,
                family_member_details: action.payload
            }
        case RESIDENTS_FETCH_FAMILY_MEMBERS:
            return {
                ...state,
                family_members: action.payload
            }
        case RESIDENTS_FETCH_MEDICAL_REPORT:
            return {
                ...state,
                medical_report_details: action.payload
            }
        case RESIDENTS_FETCH_MEDICAL_REPORTS:
            return {
                ...state,
                medical_reports: action.payload
            }
        case RESIDENTS_FETCH_RESIDENT:
            return {
                ...state,
                resident_details: action.payload
            }
        case RESIDENTS_FETCH_RESIDENTS:
            return {
                ...state,
                residents: action.payload
            }
        case RESIDENTS_UPDATE_RESIDENT_DETAILS:
            return {
                ...state,
                resident_details: {
                    ...state.resident_details,
                    [action.payload.name]: action.payload.value
                }
            }
        case RESIDENTS_CLEAR_RESIDENT_DETAILS:
            return {
                ...state,
                resident_details: {...DefaultResident}
            }
        case RESIDENTS_CLEAR_MEDICAL_REPORT: {
            return {
                ...state,
                medical_report_details: {...DefaultMedialReport}
            }
        }
        case RESIDENTS_UPDATE_MEDICAL_REPORT: {
            return {
                ...state,
                medical_report_details: {
                    ...state.medical_report_details,
                    [action.payload.name]: action.payload.value
                }
            }
        }
        default:
            return {
                ...state
            }
    }
}
