import React from "react";
import {Button, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyle = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: theme.spacing(7)
    }
}))

export const AccountsHeader = (props) => {
    const classes = useStyle();
    return (
        <div className={classes.root}>
            <div>
                <Typography variant={"h3"}>Accounts</Typography>
                <Typography variant={"body1"}>Easily navigate through your employees, view and edit
                    their employment details.</Typography>
            </div>
            <Button color={'secondary'}
                    size={'large'}
                    variant={'contained'}
                    onClick={props.callback}
            >
                Create Account
            </Button>
        </div>
    )
}
