import React from "react";
import {Card, CardContent, CardHeader, Grid, Typography} from "@material-ui/core";
import {
    Area,
    Bar,
    BarChart,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    RadialBar,
    RadialBarChart,
    Scatter,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    header: {
        marginBottom: theme.spacing(7),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    focus: {
        backgroundColor: theme.palette.background.paper
    }
}))

const composite_data = [
    {
        name: 'Page A', uv: 590, pv: 800, amt: 1400, cnt: 490,
    },
    {
        name: 'Page B', uv: 868, pv: 967, amt: 1506, cnt: 590,
    },
    {
        name: 'Page C', uv: 1397, pv: 1098, amt: 989, cnt: 350,
    },
    {
        name: 'Page D', uv: 1480, pv: 1200, amt: 1228, cnt: 480,
    },
    {
        name: 'Page E', uv: 1520, pv: 1108, amt: 1100, cnt: 460,
    },
    {
        name: 'Page F', uv: 1400, pv: 680, amt: 1700, cnt: 380,
    },
];

const line_chart_data = [
    {
        name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
    },
    {
        name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    },
    {
        name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
    },
    {
        name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    },
    {
        name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
    },
    {
        name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    },
    {
        name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    },
];

const line_chart_data2 = [
    {
        name: 'Page A', uv: 1000, pv: 2400, amt: 2400,
    },
    {
        name: 'Page B', uv: 1200, pv: 1398, amt: 2210,
    },
    {
        name: 'Page C', uv: 1300, pv: 9800, amt: 2290,
    },
    {
        name: 'Page D', uv: 1500, pv: 3908, amt: 2000,
    },
    {
        name: 'Page E', uv: 1600, pv: 4800, amt: 2181,
    },
    {
        name: 'Page F', uv: 1800, pv: 3800, amt: 2500,
    },
    {
        name: 'Page G', uv: 2000, pv: 4300, amt: 2100,
    },
];

const data01 = [
    {name: 'Group A', value: 400}, {name: 'Group B', value: 300},
    {name: 'Group C', value: 300}, {name: 'Group D', value: 200},
];
const data02 = [
    {name: 'A1', value: 100},
    {name: 'A2', value: 300},
    {name: 'B1', value: 100},
    {name: 'B2', value: 80},
    {name: 'B3', value: 40},
    {name: 'B4', value: 30},
    {name: 'B5', value: 50},
    {name: 'C1', value: 100},
    {name: 'C2', value: 200},
    {name: 'D1', value: 150},
    {name: 'D2', value: 50},
];


const radial_data = [
    {
        name: '18-24', uv: 31.47, pv: 2400, fill: '#8884d8',
    },
    {
        name: '25-29', uv: 26.69, pv: 4567, fill: '#83a6ed',
    },
    {
        name: '30-34', uv: 15.69, pv: 1398, fill: '#8dd1e1',
    },
    {
        name: '35-39', uv: 8.22, pv: 9800, fill: '#82ca9d',
    },
    {
        name: '40-49', uv: 8.63, pv: 3908, fill: '#a4de6c',
    },
    {
        name: '50+', uv: 2.63, pv: 4800, fill: '#d0ed57',
    },
    {
        name: 'unknow', uv: 6.67, pv: 4800, fill: '#ffc658',
    },
];

export const Statistics = () => {
    const classes = useStyles();
    const style = {
        top: 0,
        left: 350,
        lineHeight: '24px',
    };
    return (
        <>
            <div className={classes.header}>
                <div>
                    <Typography variant={"h3"}>{"Statistics"}</Typography>
                    <Typography
                        variant={"body1"}>{"View and analyze data on the go"}</Typography>
                </div>
            </div>
            <Grid container spacing={6}>
                <Grid item xs={12} md={4} component={Card}>
                    <LineChart data={line_chart_data} height={300} width={450}>
                        <CartesianGrid stroke="#f5f5f5" strokeDasharray={"3 3"}/>
                        <XAxis dataKey={'name'} stroke={'white'}/>
                        <YAxis stroke={'white'}/>
                        <Legend/>
                        <Tooltip/>
                        <Line type={'monotone'} dataKey={'uv'} stroke={'red'}/>
                        <Line type={'monotone'} dataKey={'pv'} stroke={'pink'}/>
                        <Line type={'monotone'} dataKey={'amt'} stroke={'yellow'}/>
                    </LineChart>
                </Grid>
                <Grid item xs={12} md={4}>
                    <LineChart data={line_chart_data2} height={300} width={450}>
                        <CartesianGrid stroke="#f5f5f5"/>
                        <XAxis dataKey={'name'} stroke={'white'}/>
                        <YAxis stroke={'white'}/>
                        <Tooltip/>
                        <Line type={'monotone'} dataKey={'uv'} stroke={'orange'}/>
                        <Line type={'monotone'} dataKey={'pv'}/>
                    </LineChart>
                </Grid>
                <Grid item xs={12} md={4}>
                    <LineChart data={line_chart_data2} height={300} width={450}>
                        <CartesianGrid stroke="#f5f5f5"/>
                        <XAxis dataKey={'name'} stroke={'white'}/>
                        <YAxis stroke={'white'}/>
                        <Tooltip/>
                        <Line type={'monotone'} dataKey={'uv'} stroke={'orange'}/>
                        {/*<Line type={'monotone'} dataKey={'pv'}/>*/}
                    </LineChart>
                </Grid>
                <Grid item xs={12} md={6} style={{marginTop: '70px'}}>
                    <ComposedChart
                        width={700}
                        height={400}
                        data={composite_data}
                    >
                        <CartesianGrid stroke="#f5f5f5"/>
                        <XAxis dataKey={'name'} stroke={'white'}/>
                        <YAxis stroke={'white'}/>
                        <Tooltip/>
                        <Legend/>
                        <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8"/>
                        <Bar dataKey="pv" barSize={20} fill="#413ea0"/>
                        <Line type="monotone" dataKey="uv" stroke="#ff7300"/>
                        <Scatter dataKey="cnt" fill="red"/>
                    </ComposedChart>
                </Grid>
                <Grid item xs={12} md={6} style={{marginTop: '70px'}}>
                    <BarChart
                        width={700}
                        height={400}
                        data={line_chart_data}
                    >
                        <CartesianGrid stroke="#f5f5f5"/>
                        <XAxis dataKey={'name'} stroke={'white'}/>
                        <YAxis stroke={'white'}/>
                        <Tooltip/>
                        <Legend/>
                        <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8"/>
                        <Bar dataKey="pv" barSize={20} fill="#413ea0"/>
                        <Bar dataKey="uv" barSize={20} fill="red"/>
                    </BarChart>
                </Grid>
                <Grid item xs={12} md={12} style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly'
                }}>
                    <Card>
                        <CardHeader title={<Typography variant={'h6'}>
                            Lorem ipsum dolor sit amet.
                        </Typography>}/>
                        <CardContent>
                            <PieChart width={400} height={400}>
                                <Pie data={data01} dataKey="value" cx={200} cy={200} outerRadius={60} fill="#8884d8"/>
                                <Pie data={data02} dataKey="value" cx={200} cy={200} innerRadius={70} outerRadius={90}
                                     fill="#82ca9d" label/>
                                <Legend/>
                                <Tooltip/>
                            </PieChart>
                        </CardContent>
                    </Card>
                    <PieChart width={400}
                              height={400}
                              innerRadius={60}
                              outerRadius={80}
                    >
                        <Pie data={data02} dataKey="value" cx={200} cy={200} innerRadius={70} outerRadius={90}
                             fill="#82ca9d" paddingAngle={5} label/>
                    </PieChart>
                    <RadialBarChart width={500} height={300} cx={150} cy={150} innerRadius={20} outerRadius={140}
                                    barSize={10} data={radial_data}>
                        <RadialBar minAngle={15} label={{position: 'insideStart', fill: '#fff'}} background clockWise
                                   dataKey="uv"/>
                        <Legend iconSize={10} width={120} height={140} layout="vertical" verticalAlign="middle"
                                wrapperStyle={style}/>
                    </RadialBarChart>
                </Grid>
            </Grid>
        </>
    )
}
