import React, {useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {AuthUserPosition} from "./Constants";
import FormHelperText from "@material-ui/core/FormHelperText";

export const AuthUserPositionSelector = ({onSelect, initPositions, can_edit}) => {
    const [state, setState] = useState({
        guardian: initPositions.guardian,
        field_worker_lead: initPositions.field_worker_lead,
        field_worker: initPositions.field_worker,
        caretaker: initPositions.caretaker,
        caretaker_lead: initPositions.caretaker_lead,
        operator: initPositions.operator,
        operator_lead: initPositions.operator_lead,
        executive: initPositions.executive
    });

    const handleChange = event => {
        if (!can_edit) return;
        const {name, checked} = event.target;
        let newSelected = [];
        for (const [key, value] of Object.entries(state)) {
            if (value) {
                newSelected.push(key)
            }
        }
        if (checked) {
            newSelected.push(name)
        } else {
            newSelected = newSelected.filter(it => it !== name);
        }
        setState({
            ...state,
            [name]: checked
        })
        onSelect('positions', newSelected);
    }

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Select auth user positions</FormLabel>
            <FormGroup row>
                {
                    Object.entries(AuthUserPosition).map(([k, v]) => {
                        return (
                            <FormControlLabel
                                key={k}
                                control={<Checkbox name={k}
                                                   checked={state[k]}
                                                   onChange={handleChange}
                                                   color={"primary"}/>}
                                label={v}
                            />
                        )
                    })
                }
            </FormGroup>
            <FormHelperText>Choosing the right roles sets the user's access across the application</FormHelperText>
        </FormControl>
    )
}
