import React from "react";
import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {GenderSelect} from "../shared/GenderSelect";
import {EmployeeTypeSelector} from "../shared/EmployeeTypeSelector";
import {DatePicker} from "@material-ui/pickers";

export const EmployeeForm = ({
                                 onFieldChange,
                                 mobile_phone,
                                 employee_type,
                                 date_of_birth,
                                 hire_date,
                                 gender,
                                 work_hours,
                                 department,
                                 can_edit
                             }) => {

    const handleChange = (event) => {
        const {name, value} = event.target
        onFieldChange(name, value)
    }

    const handleFieldChange = (name, value) => {
        onFieldChange(name, value)
    }

    return (
        <>
            <Grid item xs={12} md={6}>
                <TextField
                    variant={'standard'}
                    type={'text'}
                    name={'mobile_phone'}
                    label={'Mobile phone'}
                    onChange={handleChange}
                    value={mobile_phone}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}/>
            <Grid item xs={12} md={6}>
                <EmployeeTypeSelector onFieldChange={(name, value) => handleFieldChange(name, value)}
                                      initialEmployeeTypeState={employee_type}
                                      can_edit={can_edit}
                />
            </Grid>
            {/*<Grid item xs={12} md={6}>*/}
            {/*    <EmployeePositionSelector onFieldChange={(name, value) => handleFieldChange(name, value)}*/}
            {/*                              initPosition={position}*/}
            {/*                              can_edit={can_edit}*/}
            {/*    />*/}
            {/*</Grid>*/}
            <Grid item xs={12} md={6}>
                <DatePicker
                    helperText="Date of birth"
                    name={'date_of_birth'}
                    type="date"
                    format="YYYY-MM-DD"
                    onChange={date => handleFieldChange('date_of_birth', date.format("YYYY-MM-DD"))}
                    value={date_of_birth}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <DatePicker
                    helperText="Hire date"
                    name={'hire_date'}
                    onChange={date => handleFieldChange('hire_date', date.format("YYYY-MM-DD"))}
                    value={hire_date}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <GenderSelect onSelect={(name, value) => onFieldChange(name, value)}
                              initialGender={gender}
                              can_edit={can_edit}
                />
            </Grid>
            <Grid item xs={12} md={2}>
                <TextField
                    helperText="Work hours"
                    name={'work_hours'}
                    type="number"
                    onChange={handleChange}
                    value={work_hours}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    variant={'standard'}
                    type={'text'}
                    name={'department'}
                    label={'Department'}
                    onChange={handleChange}
                    value={department}
                    fullWidth
                />
            </Grid>
        </>
    )
}
