import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import {Button, Grid, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import * as actions from "../../store/actions"
import {useParams} from "react-router";
import {useAuth0} from "@auth0/auth0-react";
import {Spinner} from "../shared/Spinner";
import {makeStyles} from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const useStyle = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(7),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}))

const DoctorForm = ({doctor, onDoctorFetch, onDoctorUpdate, onDoctorCreate, onClearDoctorDetails, onDoctorSave}) => {
    let disabled = false;
    const {getAccessTokenSilently} = useAuth0()
    const {doctor_id} = useParams()
    const [can_edit, set_editable] = useState(false)
    const classes = useStyle()

    useEffect(() => {
        doctor_id ?
            getAccessTokenSilently().then(token => {
                onDoctorFetch(token, doctor_id)
            }) : onClearDoctorDetails()
    }, [])

    const handleChange = (event) => {
        const {name, value} = event.target;
        onDoctorUpdate(name, value)
    }

    const handleCreate = () => {
        getAccessTokenSilently().then(token => {
            onDoctorCreate(token, doctor)
        })
    }

    const handleSave = () => {
        getAccessTokenSilently().then(token => {
            onDoctorSave(token, doctor_id, doctor)
        })
    }

    if (!doctor)
        return <Spinner/>

    return (
        <React.Fragment>
            <div className={classes.header}>
                <div>
                    <Typography
                        variant={"h3"}>{doctor_id ? "Doctor Details" : "Doctor report"}</Typography>
                    <Typography
                        variant={"body1"}>{doctor_id ? "View and update doctor details" : "Create new doctor"}</Typography>
                </div>
                {
                    doctor_id && <FormControlLabel
                        control={
                            <Switch checked={can_edit}
                                    onChange={e => set_editable(e.target.checked)}
                                    name="disabled"
                                    color={'primary'}/>
                        }
                        label="Edit doctor"
                    />
                }
            </div>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'first_name'}
                        value={doctor.first_name}
                        label={'First Name'}
                        onChange={handleChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'last_name'}
                        value={doctor.last_name}
                        label={'Last Name'}
                        onChange={handleChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'specialization'}
                        value={doctor.specialization}
                        label={'Specialization'}
                        onChange={handleChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'main_hospital'}
                        value={doctor.main_hospital}
                        label={'Main Hospital'}
                        onChange={handleChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'mobile_phone'}
                        value={doctor.mobile_phone}
                        label={'Mobile Phone'}
                        onChange={handleChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'work_phone'}
                        value={doctor.work_phone}
                        label={'Work Phone'}
                        onChange={handleChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'email'}
                        value={doctor.email}
                        label={'Email'}
                        onChange={handleChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    {
                        !doctor_id && <Button color={'secondary'}
                                              size={'large'}
                                              variant={'contained'}
                                              onClick={handleCreate}
                        >
                            Create Account
                        </Button>
                    }
                    {
                        can_edit && doctor_id && <Button color={'secondary'}
                                                         size={'large'}
                                                         variant={'contained'}
                                                         onClick={handleSave}
                        >
                            Save Account
                        </Button>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

const mapStateToProps = store => {
    return {
        doctor: store.medications.doctor_details
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onDoctorFetch: (token, id) => dispatch(actions.fetchDoctor(token, id)),
        onDoctorUpdate: (name, value) => dispatch(actions.updateDoctorDetails(name, value)),
        onDoctorSave: (token, id, data) => dispatch(actions.saveDoctor(token, id, data)),
        onClearDoctorDetails: () => dispatch(actions.clearDoctorDetails()),
        onDoctorCreate: (token, data) => dispatch(actions.createDoctor(token, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorForm)
