import React from "react";

const Tasks = (props) => {
    return (
        <div>
            Tasks Works!
        </div>
    )
}

export default Tasks
