import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import {Grid} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {getConfig} from "../../configs/config_loader";
import {Spinner} from "../shared/Spinner";


export const GuardianForm = ({
                                 onFieldChange,
                                 mobile_phone,
                                 mobile_phone2,
                                 home_phone,
                                 work_phone,
                                 work_hours_from,
                                 work_hours_to,
                                 work_weekends,
                                 can_edit
                             }) => {

    const handleChange = (event) => {
        if (!can_edit) return;
        const {name, value} = event.target
        onFieldChange(name, value)
    }

    const handleCheck = (event) => {
        if (!can_edit) return;
        const {name, checked} = event.target
        onFieldChange(name, checked)
    }

    const [config, set_config] = useState(null);

    useEffect(() => {
        getConfig().then(set_config)
    }, [])

    if (!config)
        return <Spinner/>;

    return (
        <>
            {
                config.fields.accounts.guardian.mobile_phone && <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'mobile_phone'}
                        label={'Mobile phone'}
                        value={mobile_phone}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
            }
            {
                config.fields.accounts.guardian.mobile_phone2 && <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'mobile_phone2'}
                        label={'Mobile phone 2'}
                        value={mobile_phone2}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
            }
            {
                config.fields.accounts.guardian.home_phone && <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'home_phone'}
                        label={'Home phone'}
                        value={home_phone}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
            }
            {
                config.fields.accounts.guardian.work_phone && <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'work_phone'}
                        label={'Work phone'}
                        value={work_phone}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
            }
            {
                config.fields.accounts.guardian.work_hours_from && <Grid item xs={12} md={2}>
                    <TextField
                        variant={'standard'}
                        type={'time'}
                        name={'work_hours_from'}
                        helperText={'Work hours from'}
                        onChange={handleChange}
                        value={work_hours_from}
                        inputProps={{
                            step: 300,
                        }}
                        fullWidth
                    />
                </Grid>
            }
            {
                config.fields.accounts.guardian.work_hours_to && <Grid item xs={12} md={2}>
                    <TextField
                        variant={'standard'}
                        type={'time'}
                        name={'work_hours_to'}
                        helperText={'Work hours to'}
                        onChange={handleChange}
                        value={work_hours_to}
                        inputProps={{
                            step: 300,
                        }}
                        fullWidth
                    />
                </Grid>
            }
            {
                config.fields.accounts.guardian.work_weekends && <Grid item xs={12} md={2}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={work_weekends}
                                onChange={handleCheck}
                                value={work_weekends}
                                name="work_weekends"
                                color="primary"
                            />
                        }
                        label="Works weekends"
                    />
                </Grid>
            }
        </>
    )
}
