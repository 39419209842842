import React from "react";
import {makeStyles} from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))

export const Spinner = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CircularProgress color={"secondary"}/>
        </div>
    )
}
