import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Avatar, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router";

const styles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(1, 4),
        marginBottom: theme.spacing(3),
        display: 'flex',
        alignItems: 'center'
    },
    content: {
        width: '100%',
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center',
        paddingBottom: `${theme.spacing(2)}px !important`
    },
    credential: {
        width: '25%',
    },
    contact_box: {
        width: '30%',
        textAlign: 'left'
    }
}))

export const AccountsListCard = ({account, account_type}) => {
    const classes = styles();
    const history = useHistory()
    const handleAccountDetails = () => {
        history.push(`/accounts/${account_type}/${account.id}`);
    }

    return (
        <Card className={classes.root} onClick={handleAccountDetails}>
            <Avatar variant={"circle"}>{account.auth_user.first_name[0]}</Avatar>
            <CardContent className={classes.content}>
                <div className={classes.credential}>
                    <Typography variant={"h5"}>{account.auth_user.first_name} {account.auth_user.last_name}</Typography>
                    <Typography
                        variant={"body2"}>
                        {account.position} {account.department ? `at ${account.department} department` : null}
                    </Typography>
                </div>
                <div className={classes.contact_box}>
                    <Typography variant={"body1"}>Phone: {account.mobile_phone}</Typography>
                    <Typography variant={"body1"}>Email: {account.auth_user.email}</Typography>
                </div>
                {
                    account_type === 'employee'
                        ? <div className={classes.contact_box}>
                            <Typography variant={"body1"}>Employment type: {account.employee_type}</Typography>
                        </div>
                        : <div className={classes.contact_box}>
                            <Typography variant={"body1"}>City: {account.city}</Typography>
                            <Typography variant={"body1"}>Address: {account.address_1}</Typography>
                        </div>
                }
            </CardContent>
        </Card>
    )

}
