import {
    EVENTS_FETCH_DOCTOR_EVENT,
    EVENTS_FETCH_DOCTOR_EVENTS,
    EVENTS_FETCH_EVENT,
    EVENTS_FETCH_EVENTS,
    EVENTS_FETCH_FIELD_WORKER_VISIT,
    EVENTS_FETCH_FIELD_WORKER_VISITS,
    EVENTS_FETCH_TASK_EVENT,
    EVENTS_FETCH_TASK_EVENTS,
    EVENTS_FETCH_VISIT,
    EVENTS_FETCH_VISITS
} from '../actions/action.types'

const initialState = {}

export const eventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case EVENTS_FETCH_DOCTOR_EVENT:
            return {
                ...state,
                doctor_event_details: action.payload
            };
        case EVENTS_FETCH_DOCTOR_EVENTS:
            return {
                ...state,
                doctor_events: action.payload
            };
        case EVENTS_FETCH_EVENT:
            return {
                ...state,
                event_details: action.payload
            };
        case EVENTS_FETCH_EVENTS:
            return {
                ...state,
                events: action.payload
            };
        case EVENTS_FETCH_FIELD_WORKER_VISIT:
            return {
                ...state,
                field_worker_visit_event_details: action.payload
            };
        case EVENTS_FETCH_FIELD_WORKER_VISITS:
            return {
                ...state,
                field_worker_visit_events: action.payload
            };
        case EVENTS_FETCH_TASK_EVENT:
            return {
                ...state,
                task_event_details: action.payload
            };
        case EVENTS_FETCH_TASK_EVENTS:
            return {
                ...state,
                task_events: action.payload
            };
        case EVENTS_FETCH_VISIT:
            return {
                ...state,
                visit_event_details: action.payload
            };
        case EVENTS_FETCH_VISITS:
            return {
                ...state,
                visit_events: action.payload
            };
        default:
            return {
                ...state
            }
    }
}
