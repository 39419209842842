import {
    TASKS_FETCH_COMMENT,
    TASKS_FETCH_COMMENT_ATTACHMENT,
    TASKS_FETCH_COMMENT_ATTACHMENTS,
    TASKS_FETCH_COMMENTS,
    TASKS_FETCH_TASK,
    TASKS_FETCH_TASK_ATTACHMENT,
    TASKS_FETCH_TASK_ATTACHMENTS,
    TASKS_FETCH_TASKS
} from "../actions/action.types";

const initialState = {}

export const tasksReducer = (state = initialState, action) => {
    switch (action.type) {
        case TASKS_FETCH_COMMENT:
            return {
                ...state,
                comment_details: action.payload
            }
        case TASKS_FETCH_COMMENTS:
            return {
                ...state,
                comments: action.payload
            }
        case TASKS_FETCH_COMMENT_ATTACHMENT:
            return {
                ...state,
                comment_attachment_details: action.payload
            }
        case TASKS_FETCH_COMMENT_ATTACHMENTS:
            return {
                ...state,
                comment_attachments: action.payload
            }
        case TASKS_FETCH_TASK:
            return {
                ...state,
                task_details: action.payload
            }
        case TASKS_FETCH_TASKS:
            return {
                ...state,
                tasks: action.payload
            }
        case TASKS_FETCH_TASK_ATTACHMENT:
            return {
                ...state,
                task_attachment_details: action.payload
            }
        case TASKS_FETCH_TASK_ATTACHMENTS:
            return {
                ...state,
                task_attachments: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
