import React, {useState} from "react";
import {NavbarComponent} from "../components/navigation/Navbar";
import {ThemeProvider} from '@material-ui/styles';
import {theme, theme_dark, theme_light} from "./Theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles} from "@material-ui/core/styles";

export const drawerWidth = 300

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        minHeight: '90vh',
        paddingLeft: '300px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    content: {
        width: '75vw',
        paddingTop: '50px',
        paddingBottom: '50px'
    }
}))

export const Layout = (props) => {
    const classes = useStyles();
    const [is_dark, set_is_dark] = useState(true);
    return (
        <React.Fragment>
            <ThemeProvider theme={is_dark ? theme_dark : theme_light}>
                <CssBaseline/>
                <NavbarComponent is_dark={is_dark} toggleTheme={set_is_dark}/>
                <div className={classes.container}>
                    <main className={classes.content}>
                        {props.children}
                    </main>
                </div>
            </ThemeProvider>
        </React.Fragment>
    )
}
