import React, {useEffect, useState} from 'react'
import {DashboardCardComponent} from "./DashboardCard";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/styles";
import {useAuth0} from "@auth0/auth0-react";
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Spinner} from "../shared/Spinner";
import {getConfig} from "../../configs/config_loader";

const dashboard_items = [
    {
        title: "Accounts",
        image: "accounts.svg",
        route: "/accounts",
        text: "Manage employee accounts",
        module: 'accounts'
    },
    {
        title: "Events",
        image: "events.svg",
        route: "/events",
        text: "Schedule important events",
        module: 'events'
    },
    {
        title: "Medications",
        image: "medications.svg",
        route: "/medications",
        text: "Manage resident medications",
        module: 'medications'
    },
    {
        title: "Residents",
        image: "residents.svg",
        route: "/residents",
        text: "Manage your residents",
        module: 'residents'
    },
    {
        title: "Tasks",
        image: "tasks.svg",
        route: "/tasks",
        text: "See what needs to be done next",
        module: 'tasks'
    },
    {
        title: "Statistics",
        image: "stats.svg",
        route: "/stats",
        text: "See the data visualized",
        module: 'statistics'
    }
]

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: "none",
    },
    cta: {
        fontSize: '1.2em',
        padding: theme.spacing(1, 6),
        marginTop: theme.spacing(2)
    }
}))

const Dashboard = () => {
    const classes = useStyles();
    const {isAuthenticated, loginWithRedirect, isLoading} = useAuth0()
    const [config, set_config] = useState(null)

    useEffect(() => {
        getConfig().then(set_config)
    }, [])

    if (isLoading || !config)
        return <Spinner/>

    return (
        isAuthenticated
            ? <Grid container spacing={3}>
                {
                    dashboard_items.filter(card => config.modules.indexOf(card.module) !== -1).map(it =>
                        <Grid item xs={12} md={6} lg={4} key={it.title}>
                            <Link to={it.route} className={classes.link}>
                                <DashboardCardComponent
                                    title={it.title}
                                    image={it.image}
                                    text={it.text}
                                />
                            </Link>
                        </Grid>
                    )
                }
            </Grid>
            : <div className={classes.root}>
                <Typography variant={"h1"}>Welcome to Carrie</Typography>
                <Button className={classes.cta}
                        color={"secondary"}
                        size={"large"}
                        variant={"contained"}
                        onClick={() => loginWithRedirect()}>
                    Log In
                </Button>
            </div>
    )
}

export default Dashboard
