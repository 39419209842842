import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {AuthUserPositionSelector} from "../shared/AuthUserPositionSelector";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {AuthUserType} from "../shared/Constants";
import MenuItem from "@material-ui/core/MenuItem";

export const AuthForm = ({
                             first_name,
                             last_name,
                             email,
                             password,
                             confirm_password,
                             user_type,
                             positions,
                             isDetails,
                             isGuardian,
                             onFieldChange,
                             can_edit
                         }) => {

    const [visibility, set_visibility] = useState(false)
    const [visibility_confirm, set_visibility_confirm] = useState(false)

    const handleClickShowPassword = () => {
        set_visibility(!visibility);
    }

    const handleClickShowConfirmPassword = () => {
        set_visibility_confirm(!visibility_confirm);
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleChange = event => {
        if (!can_edit) return;
        const {name, value} = event.target;
        onFieldChange(name, value);
    }

    return (
        <>
            <Grid item xs={12} md={6}>
                <TextField
                    variant={'standard'}
                    type={'text'}
                    name={'first_name'}
                    label={'First Name'}
                    value={first_name}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    variant={'standard'}
                    type={'text'}
                    name={'last_name'}
                    label={'Last Name'}
                    value={last_name}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant={'standard'}
                    type={'email'}
                    name={'email'}
                    label={'Email'}
                    value={email}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            {
                !isDetails && (
                    <>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant={'standard'}
                                type={visibility ? 'text' : 'password'}
                                name={'password'}
                                label={'Password'}
                                onChange={handleChange}
                                value={password}
                                fullWidth
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {visibility ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant={'standard'}
                                type={visibility_confirm ? 'text' : 'password'}
                                name={'confirm_password'}
                                label={'Confirm Password'}
                                value={confirm_password}
                                onChange={handleChange}
                                fullWidth
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {visibility_confirm ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth placeholder={'User type'}>
                                <InputLabel id="user-type-label">User type</InputLabel>
                                <Select
                                    type={'text'}
                                    labelId={'user-type-label'}
                                    onChange={handleChange}
                                    name={'type'}
                                    value={user_type}
                                    fullWidth
                                >
                                    {Object.entries(AuthUserType).map(([k, v]) => <MenuItem key={k}
                                                                                            value={k}>{v}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                )}
            <Grid item xs={12}>
                {
                    !isGuardian &&
                    <AuthUserPositionSelector onSelect={(name, value) => onFieldChange(name, value)}
                                              can_edit={can_edit}
                                              initPositions={{
                                                  guardian: positions.indexOf('Guardian') !== -1,
                                                  field_worker_lead: positions.indexOf('Field worker lead') !== -1,
                                                  field_worker: positions.indexOf('Field worker') !== -1,
                                                  caretaker: positions.indexOf('Caretaker') !== -1,
                                                  caretaker_lead: positions.indexOf('Caretaker lead') !== -1,
                                                  operator: positions.indexOf('Operator') !== -1,
                                                  operator_lead: positions.indexOf('Operator lead') !== -1,
                                                  executive: positions.indexOf('Executive') !== -1,
                                              }}
                    />
                }
            </Grid>
        </>
    )
}
