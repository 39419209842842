import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";
import {accountsReducer} from './reducers/reducer.accounts'
import {eventsReducer} from "./reducers/reducer.events";
import {medicationsReducer} from "./reducers/reducer.medications";
import {residentsReducer} from "./reducers/reducer.residents";
import {tasksReducer} from "./reducers/reducer.tasks";

const rootReducer = combineReducers({
    accounts: accountsReducer,
    events: eventsReducer,
    medications: medicationsReducer,
    residents: residentsReducer,
    tasks: tasksReducer
})

export const store = createStore(rootReducer, applyMiddleware(thunk))

