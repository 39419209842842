import {createMuiTheme} from "@material-ui/core/styles";

export const theme_dark = createMuiTheme({
    palette: {
        type: 'dark'
    },
});

export const theme_light = createMuiTheme({
    palette: {
        type: 'light'
    },
});
