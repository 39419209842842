import {authorized} from '../../axios'
import {
    RESIDENTS_CLEAR_MEDICAL_REPORT,
    RESIDENTS_CLEAR_RESIDENT_DETAILS,
    RESIDENTS_FETCH_EMERGENCY_CONTACT,
    RESIDENTS_FETCH_EMERGENCY_CONTACTS,
    RESIDENTS_FETCH_FAMILY_MEMBER,
    RESIDENTS_FETCH_FAMILY_MEMBERS,
    RESIDENTS_FETCH_MEDICAL_REPORT,
    RESIDENTS_FETCH_MEDICAL_REPORTS,
    RESIDENTS_FETCH_RESIDENT,
    RESIDENTS_FETCH_RESIDENTS,
    RESIDENTS_UPDATE_MEDICAL_REPORT,
    RESIDENTS_UPDATE_RESIDENT_DETAILS
} from './action.types'
import {resident_request_formatter} from "../../components/form_parsers/resident_request_formatter";

const primary_slug = 'residents/api/v1'

export const fetchResident = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/resident/${id}/`)
        .then(resp => dispatch({
            type: RESIDENTS_FETCH_RESIDENT,
            payload: resp.data
        }))
}

export const fetchResidents = (token) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/resident/`)
        .then(resp => dispatch({
            type: RESIDENTS_FETCH_RESIDENTS,
            payload: resp.data
        }))
}

export const fetchMedicalReport = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/medical_report/${id}/`)
        .then(resp => dispatch({
            type: RESIDENTS_FETCH_MEDICAL_REPORT,
            payload: resp.data
        }))
}

export const fetchMedicalReports = (token) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/medical_report/`)
        .then(resp => dispatch({
            type: RESIDENTS_FETCH_MEDICAL_REPORTS,
            payload: resp.data
        }))
}

export const fetchFamilyMember = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/family_member/${id}/`)
        .then(resp => dispatch({
            type: RESIDENTS_FETCH_FAMILY_MEMBER,
            payload: resp.data
        }))
}

export const fetchFamilyMembers = (token) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/family_member/`)
        .then(resp => dispatch({
            type: RESIDENTS_FETCH_FAMILY_MEMBERS,
            payload: resp.data
        }))
}

export const fetchEmergencyContact = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/emergency_contact/${id}/`)
        .then(resp => dispatch({
            type: RESIDENTS_FETCH_EMERGENCY_CONTACT,
            payload: resp.data
        }))
}

export const fetchEmergencyContacts = (token) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/emergency_contact/`)
        .then(resp => dispatch({
            type: RESIDENTS_FETCH_EMERGENCY_CONTACTS,
            payload: resp.data
        }))
}

export const updateResidentDetails = (name, value) => dispatch => {
    dispatch({
        type: RESIDENTS_UPDATE_RESIDENT_DETAILS,
        payload: {name, value}
    })
}

export const clearResidentDetails = () => dispatch => {
    dispatch({
        type: RESIDENTS_CLEAR_RESIDENT_DETAILS
    })
}

export const createResident = (token, data) => dispatch => {
    resident_request_formatter(data).then(formatted_data => {
        authorized(token)
            .post(`${primary_slug}/resident/`, formatted_data)
            .then(() => alert('Success'))
            .catch(alert)
    })
}

export const updateReportDetails = (name, value) => dispatch => {
    dispatch({
        type: RESIDENTS_UPDATE_MEDICAL_REPORT,
        payload: {name, value}
    })
}

export const clearReportDetails = () => dispatch => {
    dispatch({
        type: RESIDENTS_CLEAR_MEDICAL_REPORT
    })
}

export const createReport = (token, data) => dispatch => {
    authorized(token)
        .post(`${primary_slug}/medical_report/`, data)
        .then(() => alert('Success'))
        .catch(alert)
}

export const saveResident = (token, id, data) => dispatch => {
    resident_request_formatter(data).then(formatted_data => {
        authorized(token)
            .put(`${primary_slug}/resident/${id}/`, formatted_data)
            .then(() => alert('Success'))
            .catch(alert)
    })
}

export const saveReport = (token, id, data) => dispatch => {
    authorized(token)
        .put(`${primary_slug}/medical_report/${id}/`, data)
        .then(() => alert('Success'))
        .catch(alert)
}
