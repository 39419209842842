import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import React from "react";
import 'react-big-calendar/lib/css/react-big-calendar.css'

const localizer = momentLocalizer(moment)

const myEventsList = [
    {
        id: 0,
        title: 'Board meeting',
        start: new Date(),
        end: new Date(),
        resourceId: 1,
    },
    {
        id: 1,
        title: 'MS training',
        allDay: true,
        start: new Date(),
        end: new Date(),
        resourceId: 2,
    },
    {
        id: 2,
        title: 'Team lead meeting',
        start: new Date(),
        end: new Date(),
        resourceId: 3,
    },
    {
        id: 11,
        title: 'Birthday Party',
        start: new Date(),
        end: new Date(),
        resourceId: 4,
    },
]

export const MyCalendar = props => (
    <Calendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={myEventsList}
        style={{height: "80vh"}}
    />
)
