import React from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(7)
    }
}))

export const RegisterHeader = ({onFieldChange, can_edit, displayEditable}) => {
    const classes = useStyles();

    const handleChange = (e) => {
        const {checked} = e.target;
        onFieldChange(checked)
    }

    return (
        <div className={classes.root}>
            <div>
                <Typography variant={"h3"}>{displayEditable ? 'Account Details' : 'Register'}</Typography>
                <Typography
                    variant={"body1"}>{displayEditable ? 'View and edit application users' : 'Create application users'}</Typography>
            </div>

            {
                displayEditable && <FormControlLabel
                    control={<Switch checked={can_edit}
                                     onChange={handleChange}
                                     name="disabled"
                                     color={'primary'}/>}
                    label="Edit user"
                />
            }
        </div>
    )
}
