import {authorized} from '../../axios'

import {
    MEDICATIONS_CLEAR_DIAGNOSIS_DETAILS,
    MEDICATIONS_CLEAR_DOCTOR_DETAILS,
    MEDICATIONS_FETCH_ALL_DIAGNOSIS,
    MEDICATIONS_FETCH_DIAGNOSIS,
    MEDICATIONS_FETCH_DOCTOR,
    MEDICATIONS_FETCH_DOCTORS,
    MEDICATIONS_FETCH_MEDICAL_EXAMINATION,
    MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPE,
    MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPES,
    MEDICATIONS_FETCH_MEDICAL_EXAMINATIONS,
    MEDICATIONS_FETCH_MEDICATION,
    MEDICATIONS_FETCH_MEDICATION_SCHEDULE,
    MEDICATIONS_FETCH_MEDICATION_SCHEDULES,
    MEDICATIONS_FETCH_MEDICATIONS,
    MEDICATIONS_FETCH_THERAPIES,
    MEDICATIONS_FETCH_THERAPY,
    MEDICATIONS_FETCH_THERAPY_SCHEDULE,
    MEDICATIONS_FETCH_THERAPY_SCHEDULES,
    MEDICATIONS_UPDATE_DIAGNOSIS_DETAILS,
    MEDICATIONS_UPDATE_DOCTOR_DETAILS
} from "./action.types";

const primary_slug = 'medications/api/v1'

export const fetchMedicalExaminationType = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/medical_examination_type/${id}/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPES,
            payload: resp.data
        }))
}

export const fetchMedicalExaminationTypes = (token) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/medical_examination_type/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPE,
            payload: resp.data
        }))
}

export const fetchDoctor = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/doctor/${id}/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_DOCTOR,
            payload: resp.data
        }))
}

export const fetchDoctors = (token) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/doctor/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_DOCTORS,
            payload: resp.data
        }))
}

export const fetchTherapy = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/therapy/${id}/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_THERAPY,
            payload: resp.data
        }))
}

export const fetchTherapies = (token) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/therapy/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_THERAPIES,
            payload: resp.data
        }))
}

export const fetchDiagnosis = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/diagnosis/${id}/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_DIAGNOSIS,
            payload: resp.data
        }))
}

export const fetchAllDiagnosis = (token) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/diagnosis/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_ALL_DIAGNOSIS,
            payload: resp.data
        }))
}

export const fetchMedication = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/medication/${id}/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_MEDICATION,
            payload: resp.data
        }))
}

export const fetchMedications = (token) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/medication/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_MEDICATIONS,
            payload: resp.data
        }))
}

export const fetchMedicalExamination = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/medical_examination/${id}/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_MEDICAL_EXAMINATION,
            payload: resp.data
        }))
}

export const fetchMedicalExaminations = (token) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/medical_examination/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_MEDICAL_EXAMINATIONS,
            payload: resp.data
        }))
}

export const fetchMedicationSchedule = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/medication_schedule/${id}/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_MEDICATION_SCHEDULE,
            payload: resp.data
        }))
}

export const fetchMedicationSchedules = (token) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/medication_schedule/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_MEDICATION_SCHEDULES,
            payload: resp.data
        }))
}

export const fetchTherapySchedule = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/therapy_schedule/${id}/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_THERAPY_SCHEDULE,
            payload: resp.data
        }))
}

export const fetchTherapySchedules = (token) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/therapy_schedule/`)
        .then(resp => dispatch({
            type: MEDICATIONS_FETCH_THERAPY_SCHEDULES,
            payload: resp.data
        }))
}

export const updateDoctorDetails = (name, value) => dispatch => {
    dispatch({
        type: MEDICATIONS_UPDATE_DOCTOR_DETAILS,
        payload: {name, value}
    })
}

export const clearDoctorDetails = () => dispatch => {
    dispatch({
        type: MEDICATIONS_CLEAR_DOCTOR_DETAILS,
    })
}

export const createDoctor = (token, data) => dispatch => {
    authorized(token)
        .post(`${primary_slug}/doctor/`, data)
        .then(resp => alert('Success'))
        .catch(alert)
}

export const updateDiagnosisDetails = (name, value) => dispatch => {
    dispatch({
        type: MEDICATIONS_UPDATE_DIAGNOSIS_DETAILS,
        payload: {name, value}
    })
}

export const clearDiagnosisDetails = () => dispatch => {
    dispatch({
        type: MEDICATIONS_CLEAR_DIAGNOSIS_DETAILS,
    })
}

export const createDiagnosis = (token, data) => dispatch => {
    authorized(token)
        .post(`${primary_slug}/diagnosis/`, data)
        .then(resp => alert('Success'))
        .catch(alert)
}

export const saveDiagnosis = (token, id, data) => dispatch => {
    authorized(token)
        .put(`${primary_slug}/diagnosis/${id}/`, data)
        .then(resp => alert('Success'))
        .catch(alert)
}

export const saveDoctor = (token, id, data) => dispatch => {
    authorized(token)
        .put(`${primary_slug}/doctor/${id}/`, data)
        .then(resp => alert('Success'))
        .catch(alert)
}
