import {AuthUserPosition} from "./Constants";

export const formatCreateUserData = (data) => {
    let formatted_data;
    let user_data = {};
    Object.entries(data).forEach(([k, v]) => {
        if (k !== 'auth_user')
            user_data = {
                ...user_data,
                [k]: v
            }
    })
    switch (data.auth_user.type) {
        case 'employee':
            formatted_data = {
                ...data.auth_user,
                employee: {
                    ...user_data,
                    positions: data.auth_user.positions.map(it => AuthUserPosition[it])
                }
            }
            return formatted_data
        case 'guardian':
            formatted_data = {
                ...data.auth_user,
                guardian: {
                    ...user_data
                }
            }
            return formatted_data
        default:
            formatted_data = data;
    }
    return formatted_data;
}
