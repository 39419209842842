import React, {useEffect, useState} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Link} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu"
import {makeStyles} from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AssignmentIcon from '@material-ui/icons/Assignment';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import Button from "@material-ui/core/Button";
import {useAuth0} from "@auth0/auth0-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Drawer from "@material-ui/core/Drawer";
import {drawerWidth} from "../../layout/Layout";
import {Spinner} from "../shared/Spinner";
import {getConfig} from "../../configs/config_loader";
import Switch from "@material-ui/core/Switch";

const links = [
    {
        icon: <MenuIcon/>,
        to: '/',
        text: 'Dashboard',
        module: 'dashboard'
    },
    {
        icon: <SupervisorAccountIcon/>,
        to: '/accounts',
        text: 'Accounts',
        module: 'accounts'
    },
    {
        icon: <AvTimerIcon/>,
        to: '/events',
        text: 'Events',
        module: 'events'
    },
    {
        icon: <LocalHospitalIcon/>,
        to: '/medications',
        text: 'Medications',
        module: 'medications'
    },
    {
        icon: <FolderSharedIcon/>,
        to: '/residents',
        text: 'Residents',
        module: 'residents'
    },
    {
        icon: <AssignmentIcon/>,
        to: '/tasks',
        text: 'Tasks',
        module: 'tasks'
    },
    {
        icon: <AccountCircleIcon/>,
        to: '/profile',
        text: 'Profile',
        module: 'profile'
    }

]

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.primary
    },
    drawerPaper: {
        width: drawerWidth
    },
    toolbar: theme.mixins.toolbar,
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    pad: {
      paddingRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    },
    btn: {
        marginLeft: '20px'
    }
}))

export const NavbarComponent = ({is_dark, toggleTheme}) => {
    const classes = useStyles();
    const {isAuthenticated, loginWithRedirect, logout, user, isLoading} = useAuth0();
    const [config, set_config] = useState(null)

    useEffect(() => {
        getConfig().then(set_config)
    }, [])

    if (!config){
        return <Spinner/>
    }

    return (
        <React.Fragment>
            <AppBar className={classes.appBar} position={"sticky"}>
                <Toolbar>
                    <Typography variant="h6" noWrap className={classes.title}>
                        Carrie
                    </Typography>
                    {
                        isLoading
                            ? <CircularProgress color={"secondary"} size={20}/>
                            : !isAuthenticated
                            ? <Button onClick={() => loginWithRedirect()}>Login</Button>
                            : <>
                                <Switch className={classes.pad} checked={is_dark} onChange={(e) => toggleTheme(e.target.checked)} color={"default"}/>
                                <Typography className={classes.pad} variant={"body2"}>Welcome {user.name}</Typography>
                                <Button className={'btn'} color={"secondary"} onClick={() => logout()}>Logout</Button>
                            </>
                    }
                </Toolbar>
            </AppBar>
            {
                isAuthenticated
                    ? <Drawer
                        anchor={"left"}
                        variant={"permanent"}
                    >
                        <div className={classes.toolbar}/>
                        <List className={classes.drawerPaper}>
                            {
                                links.filter(link => config.modules.indexOf(link.module) !== -1).map(l =>
                                    <Link to={l.to}
                                          className={classes.link}
                                          key={l.text}
                                    >
                                        <ListItem button>
                                            <ListItemIcon>
                                                {l.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={l.text}/>
                                        </ListItem>
                                    </Link>
                                )
                            }
                        </List>
                    </Drawer>
                    : null
            }
        </React.Fragment>
    )
}
