import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import {Grid} from "@material-ui/core";
import {getConfig} from "../../configs/config_loader";
import {Spinner} from "../shared/Spinner";

export const AddressForm = ({
                                onFieldChange,
                                address_1,
                                address_2,
                                address_3,
                                address_4,
                                city,
                                postal,
                                country,
                                can_edit
                            }) => {

    const handleChange = (event) => {
        if (!can_edit) return;
        const {name, value} = event.target;
        onFieldChange(name, value)
    }

    const [config, set_config] = useState(null)

    useEffect(() => {
        getConfig().then(set_config)
    }, [])

    if (!config)
        return <Spinner/>

    return (
        <>
            {
                config.fields.accounts.address.address_1 && <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'address_1'}
                        label={'Address 1'}
                        value={address_1}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
            }
            {
                config.fields.accounts.address.address_2 && <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'address_2'}
                        label={'Address 2'}
                        value={address_2}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
            }
            {
                config.fields.accounts.address.address_3 && <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'address_3'}
                        label={'Address 3'}
                        value={address_3}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
            }
            {
                config.fields.accounts.address.address_4 && <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'address_4'}
                        label={'Address 4'}
                        value={address_4}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
            }
            {
                config.fields.accounts.address.country && <Grid item xs={5}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'country'}
                        label={'Country'}
                        onChange={handleChange}
                        value={country}
                        fullWidth
                    />
                </Grid>
            }
            {
                config.fields.accounts.address.city && <Grid item xs={5}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'city'}
                        label={'City'}
                        onChange={handleChange}
                        value={city}
                        fullWidth
                    />
                </Grid>
            }
            {
                config.fields.accounts.address.postal && <Grid item xs={2}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'postal'}
                        label={'Postal code'}
                        onChange={handleChange}
                        value={postal}
                        fullWidth
                    />
                </Grid>
            }
        </>
    )
}
