import {authorized} from '../../axios'
import {
    ACCOUNTS_CLEAR_ACCOUNT_DETAILS,
    ACCOUNTS_FETCH_ADMINISTRATOR,
    ACCOUNTS_FETCH_ADMINISTRATORS,
    ACCOUNTS_FETCH_DISTRICT_LEAD,
    ACCOUNTS_FETCH_DISTRICT_LEADS,
    ACCOUNTS_FETCH_EMPLOYEE,
    ACCOUNTS_FETCH_EMPLOYEES,
    ACCOUNTS_FETCH_GUARDIAN,
    ACCOUNTS_FETCH_GUARDIANS,
    ACCOUNTS_FETCH_OPERATOR,
    ACCOUNTS_FETCH_OPERATORS,
    ACCOUNTS_FETCH_USER,
    ACCOUNTS_FETCH_USERS,
    ACCOUNTS_LOADING_EMPLOYEE,
    ACCOUNTS_LOADING_GUARDIAN,
    ACCOUNTS_UPDATE_EMPLOYEE_DETAILS,
    ACCOUNTS_UPDATE_GUARDIAN_DETAILS
} from "./action.types";

const primary_slug = 'accounts/api/v1'


export const fetchUser = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/user/${id}/`)
        .then(resp => dispatch({
            type: ACCOUNTS_FETCH_USER,
            payload: resp.data
        }))
}

export const fetchUsers = token => dispatch => {
    authorized(token)
        .get(`${primary_slug}/user/`)
        .then(resp => dispatch({
                type: ACCOUNTS_FETCH_USERS,
                payload: resp.data
            })
        )
}

export const fetchAdministrator = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/administrator/${id}/`)
        .then(resp => dispatch({
            type: ACCOUNTS_FETCH_ADMINISTRATOR,
            payload: resp.data
        }))
}

export const fetchAdministrators = token => dispatch => {
    authorized(token)
        .get(`${primary_slug}/administrator/`)
        .then(resp => dispatch({
                type: ACCOUNTS_FETCH_ADMINISTRATORS,
                payload: resp.data
            })
        )
}

export const fetchGuardian = (token, id) => dispatch => {
    dispatch({type: ACCOUNTS_LOADING_GUARDIAN})
    authorized(token)
        .get(`${primary_slug}/guardian/${id}/`)
        .then(resp => dispatch({
            type: ACCOUNTS_FETCH_GUARDIAN,
            payload: resp.data
        }))
}

export const fetchGuardians = token => dispatch => {
    authorized(token)
        .get(`${primary_slug}/guardian/`)
        .then(resp => dispatch({
                type: ACCOUNTS_FETCH_GUARDIANS,
                payload: resp.data
            })
        )
}

export const fetchOperator = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/operator/${id}/`)
        .then(resp => dispatch({
            type: ACCOUNTS_FETCH_OPERATOR,
            payload: resp.data
        }))
}

export const fetchOperators = token => dispatch => {
    authorized(token)
        .get(`${primary_slug}/operator/`)
        .then(resp => dispatch({
                type: ACCOUNTS_FETCH_OPERATORS,
                payload: resp.data
            })
        )
}

export const fetchDistrictLead = (token, id) => dispatch => {
    authorized(token)
        .get(`${primary_slug}/district_lead/${id}/`)
        .then(resp => dispatch({
            type: ACCOUNTS_FETCH_DISTRICT_LEAD,
            payload: resp.data
        }))
}

export const fetchDistrictLeads = token => dispatch => {
    authorized(token)
        .get(`${primary_slug}/district_lead/`)
        .then(resp => dispatch({
                type: ACCOUNTS_FETCH_DISTRICT_LEADS,
                payload: resp.data
            })
        )
}

export const fetchEmployee = (token, id) => dispatch => {
    dispatch({type: ACCOUNTS_LOADING_EMPLOYEE})
    authorized(token)
        .get(`${primary_slug}/employee/${id}/`)
        .then(resp => dispatch({
            type: ACCOUNTS_FETCH_EMPLOYEE,
            payload: resp.data
        }))
}

export const fetchEmployees = token => dispatch => {
    authorized(token)
        .get(`${primary_slug}/employee/`)
        .then(resp => dispatch({
                type: ACCOUNTS_FETCH_EMPLOYEES,
                payload: resp.data
            })
        )
}

export const updateEmployeeDetails = (name, value, isAuth = false) => dispatch => {
    dispatch({
        type: ACCOUNTS_UPDATE_EMPLOYEE_DETAILS,
        payload: {
            name,
            value,
            isAuth
        }
    })
}

export const updateGuardianDetails = (name, value, isAuth = false) => dispatch => {
    dispatch({
        type: ACCOUNTS_UPDATE_GUARDIAN_DETAILS,
        payload: {
            name,
            value,
            isAuth
        }
    })
}

export const clearAccountDetails = () => dispatch => {
    dispatch({
        type: ACCOUNTS_CLEAR_ACCOUNT_DETAILS
    })
}
