import axios from 'axios'

let instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
})

instance.interceptors.response.use(
    res => res,
    error => {
        let error_message = JSON.stringify(error.response.data, null, 2)
        throw new Error(error_message);
    }
)

export const authorized = (token) => {
    instance.defaults.headers.Authorization = `Bearer ${token}`
    return instance;
}
