import React from "react";
import Card from "@material-ui/core/Card";
import {CardContent, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router";

const styles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(1, 4),
        marginBottom: theme.spacing(3),
        display: 'flex',
        alignItems: 'center'
    },
    content: {
        width: '100%',
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center',
        paddingBottom: `${theme.spacing(2)}px !important`
    },
    credential: {
        width: '25%',
    },
    contact_box: {
        width: '30%',
        textAlign: 'left'
    }
}))

export const ResidentListCard = ({resident}) => {
    const classes = styles();
    const history = useHistory();
    return (
        <Card className={classes.root} onClick={() => history.push(`/residents/details/${resident.id}`)}>
            <CardContent className={classes.content}>
                <div className={classes.credential}>
                    <Typography variant={"h5"}>{resident.full_name}</Typography>
                    <Typography variant={"body2"}>Last
                        visited: {new Date(resident.last_doctor_visit).toDateString()}</Typography>
                </div>
                <div className={classes.contact_box}>
                    <Typography variant={"body2"}>Physician: {resident.physician}</Typography>
                    <Typography variant={"body2"}>Phone: {resident.physician_telephone}</Typography>
                </div>
                <div>
                    <Typography variant={"body2"}>Doctor visits: {resident.often_doctor_visit}</Typography>
                </div>
            </CardContent>
        </Card>
    )
}
