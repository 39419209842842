import {getConfig} from "../../configs/config_loader";


export const resident_request_formatter = async (form_data) => {
    let config = await getConfig()
    let formatted_data = {}
    const resident_field_config = config.fields.residents.resident
    for (const attr in form_data) {
        if (resident_field_config[attr]) {
            formatted_data[attr] = form_data[attr]
        }
    }
    if (!form_data.require_living_assistant){
        formatted_data.assistant = ""
        formatted_data.reason_assistant = ""
    }
    if (!form_data.if_medications){
        formatted_data.medications = ""
    }
    if (!form_data.if_diet){
        formatted_data.diet = ""
    }
    if (!form_data.day_visit){
        formatted_data.reason_day_visit = ""
        formatted_data.length_day_visit = 0
    }
    return formatted_data
}
