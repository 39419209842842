import React, {useEffect} from "react";
import * as actions from "../../store/actions"
import {connect} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";
import {Button, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router";
import {ResidentListCard} from "./ResidentListCard";
import {Spinner} from "../shared/Spinner";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    cards: {
        marginTop: theme.spacing(3)
    }
}))

const Residents = (props) => {
    const history = useHistory();
    const styles = useStyles();
    const {getAccessTokenSilently} = useAuth0()

    useEffect(() => {
        getAccessTokenSilently().then(token => {
            props.onResidentsFetch(token)
        })
    }, [])

    if (!props.residents)
        return <Spinner/>

    return (
        <React.Fragment>
            <div className={styles.root}>
                <div>
                    <Typography variant={"h3"}>Residents</Typography>
                    <Typography variant={"body1"}>View and manage your residents</Typography>
                </div>
                <Button color={'secondary'}
                        size={'large'}
                        variant={'contained'}
                        onClick={() => history.push('/residents/create')}
                >Create Resident</Button>
            </div>
            <div className={styles.cards}>
                {
                    props.residents && props.residents.map(resident => {
                        return (
                            <ResidentListCard key={resident.id} resident={resident}/>
                        )
                    })
                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = store => {
    return {
        residents: store.residents.residents
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onResidentsFetch: (token) => dispatch(actions.fetchResidents(token))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Residents)
