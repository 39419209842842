import React, {useEffect, useState} from "react";
import {Button, Grid, Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import {useParams} from "react-router";
import {useAuth0} from "@auth0/auth0-react";
import {Spinner} from "../shared/Spinner";
import * as actions from '../../store/actions'
import {connect} from 'react-redux'
import {makeStyles} from "@material-ui/styles";
import {DatePicker} from "@material-ui/pickers";
import {useHistory} from 'react-router-dom'
import {getConfig} from "../../configs/config_loader";

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(7),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    section_title: {
        marginBottom: theme.spacing(3)
    },
    link: {
        cursor: 'pointer'
    },
    link_fields: {
        display: "flex",
        marginTop: theme.spacing(1),
        width: '30%',
        justifyContent: "space-between"
    }
}))

const ResidentForm = ({
                          resident,
                          guardians,
                          medical_reports,
                          onResidentCreate,
                          onGuardiansFetch,
                          onMedicalReportsFetch,
                          onResidentFetch,
                          onResidentUpdate,
                          onResidentSave,
                          onClearResidentDetails
                      }) => {

    const {resident_id} = useParams()
    const history = useHistory()
    const {getAccessTokenSilently} = useAuth0()
    const classes = useStyles();
    const [can_edit, set_editable] = useState(!resident_id || false);
    const [config, set_config] = useState(null);

    useEffect(() => {
        if (resident_id) {
            getAccessTokenSilently().then(token => {
                onResidentFetch(token, resident_id);
            })
        } else {
            onClearResidentDetails()
        }
        getAccessTokenSilently().then(token => {
            onGuardiansFetch(token);
            onMedicalReportsFetch(token);
        })

        getConfig().then(set_config)

    }, [])

    const handleChange = (event) => {
        if (!can_edit) return;
        const {name, value} = event.target
        onResidentUpdate(name, value);
    };

    const handleDateChange = (name, date) => {
        if (!can_edit) return;
        let formatted = date.format("YYYY-MM-DD")
        onResidentUpdate(name, formatted)
    }

    const handleChecked = (event) => {
        if (!can_edit) return;
        const {name, checked} = event.target
        onResidentUpdate(name, checked);
    }

    const handleCreate = () => {
        getAccessTokenSilently().then(token => {
            onResidentCreate(token, resident)
        })
    }

    const handleGuardianClick = () => {
        if (resident.guardian)
            history.push(`/accounts/guardian/${resident.guardian}`)
    }

    const handleReportClick = () => {
        if (resident.medical_report)
            history.push(`/medical_report/details/${resident.medical_report}`)
    }

    const handleSave = () => {
        getAccessTokenSilently().then(token => {
            onResidentSave(token, resident_id, resident)
        })
    }

    if (!resident || !guardians || !medical_reports || !config)
        return <Spinner/>

    return (
        <>
            <div className={classes.header}>
                <div>
                    <Typography variant={"h3"}>{resident_id ? "Residents Details" : "Create resident"}</Typography>
                    <Typography
                        variant={"body1"}>{resident_id ? "View and update resident data" : "Create new resident"}</Typography>
                </div>
                {
                    resident_id && <FormControlLabel
                        control={
                            <Switch checked={can_edit}
                                    onChange={e => set_editable(e.target.checked)}
                                    name="disabled"
                                    color={'primary'}/>
                        }
                        label="Edit resident"
                    />
                }
            </div>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Typography variant={'h5'}>
                        General Personal Information
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth placeholder={'Guardian'}>
                        <InputLabel id="guardian-label">Guardian</InputLabel>
                        <Select
                            type={'text'}
                            labelId={'guardian-label'}
                            onChange={handleChange}
                            name={'guardian'}
                            value={resident.guardian}
                            fullWidth
                        >
                            {guardians.map(g => <MenuItem key={g.id}
                                                          value={g.id}>{g.auth_user.first_name} {g.auth_user.last_name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <div className={classes.link_fields}>
                        <Typography variant={'subtitle1'}
                                    color={'primary'}
                                    onClick={handleGuardianClick}
                                    className={classes.link}
                        >
                            View details
                        </Typography>
                        <Typography variant={'subtitle1'}
                                    color={'primary'}
                                    onClick={() => history.push(`/accounts/create`)}
                                    className={classes.link}
                        >
                            Create new
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    {
                        resident_id && (
                            <>
                                <FormControl fullWidth placeholder={'Medical Report'}>
                                    <InputLabel id="report-label">Medical Report</InputLabel>
                                    <Select
                                        type={'text'}
                                        labelId={'report-label'}
                                        onChange={handleChange}
                                        name={'medical_report'}
                                        value={resident.medical_report}
                                        fullWidth
                                    >
                                        {medical_reports.map(report => <MenuItem key={report.id}
                                                                                 value={report.id}>{report.id}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                <div className={classes.link_fields}>
                                    <Typography variant={'subtitle1'}
                                                color={'primary'}
                                                onClick={handleReportClick}
                                                className={classes.link}
                                    >
                                        View details
                                    </Typography>
                                    <Typography variant={'subtitle1'}
                                                color={'primary'}
                                                onClick={() => history.push(`/medical_report/create`)}
                                                className={classes.link}
                                    >
                                        Create new
                                    </Typography>
                                </div>
                            </>
                        )
                    }
                </Grid>
                {
                    config.fields.residents.resident.full_name && <Grid item xs={12} md={6}>
                        <TextField
                            label="Full Name"
                            placeholder="Enter the resident's full name"
                            helperText="The resident's full name"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.full_name}
                            name={"full_name"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.social_no && <Grid item xs={12} md={6}><TextField
                        label="Social Number"
                        placeholder="Enter the resident's social number"
                        helperText="The resident's social number"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={resident.social_no}
                        name={"social_no"}
                        disabled={!!resident_id}
                        onChange={handleChange}
                    />
                    </Grid>
                }
                {
                    config.fields.residents.resident.address && <Grid item xs={12} md={6}>
                        <TextField
                            label="Address"
                            placeholder="Enter the resident's address"
                            helperText="The resident's address"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.address}
                            name={"address"}
                            onChange={handleChange}
                        /> </Grid>
                }
                {
                    config.fields.residents.resident.years_at_address && <Grid item xs={12} md={6}>
                        <TextField
                            label="Years at address"
                            helperText="The number of years the resident has been living on the address"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type={'number'}
                            value={resident.years_at_address}
                            name={"years_at_address"}
                            onChange={handleChange}
                        /> </Grid>

                }
                {
                    config.fields.residents.resident.gender && <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel shrink id="age">
                                Gender
                            </InputLabel>
                            <Select
                                labelId="age"
                                value={resident.gender}
                                onChange={handleChange}
                                name={"gender"}
                            >
                                <MenuItem value={"Male"}>Male</MenuItem>
                                <MenuItem value={"Female"}>Female</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                            <FormHelperText>Resident's gender</FormHelperText>
                        </FormControl>
                    </Grid>
                }

                {
                    config.fields.residents.resident.birth_date && <Grid item xs={12} md={4}>
                        <DatePicker
                            label="Birth date"
                            helperText={'The date of birth of the resident'}
                            value={resident.birth_date}
                            format={"YYYY-MM-DD"}
                            name={"birth_date"}
                            onChange={(date) => handleDateChange('birth_date', date)}
                            fullWidth
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.birth_place && <Grid item xs={12} md={4}>
                        <TextField
                            label="Birth place"
                            helperText="The city where the resident is born"
                            placeholder="PLace of birth"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.birth_place}
                            name={"birth_place"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.telephone_number && <Grid item xs={12} md={4}>
                        <TextField
                            label="Telephone number"
                            helperText="The telephone number of the resident"
                            placeholder="Telephone number"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.telephone_number}
                            name={"telephone_number"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.current_living && <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel shrink id="current_living">
                                Living environment
                            </InputLabel>
                            <Select
                                onChange={handleChange}
                                value={resident.current_living}
                                name={"current_living"}
                                labelId="current_living"
                            >
                                <MenuItem value={"Own home"}>Own Home</MenuItem>
                                <MenuItem value={"Renting"}>Renting</MenuItem>
                                <MenuItem value={"Single family"}>Single Family</MenuItem>
                                <MenuItem value={"Multi-family"}>Multi-Family</MenuItem>
                            </Select>
                            <FormHelperText>Resident's gender</FormHelperText>
                        </FormControl>
                    </Grid>
                }
                <Grid item xs={12}>
                    {
                        config.fields.residents.resident.own_car && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.own_car}
                                    name={"own_car"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Car Owner"
                        />
                    }
                    {
                        config.fields.residents.resident.drive_regularly && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.drive_regularly}
                                    name={"drive_regularly"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Regular Driver"
                        />
                    }
                    {
                        config.fields.residents.resident.maintain_car && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.maintain_car}
                                    name={"maintain_car"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Maintain a car"
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h5'}>
                        General Living Information
                    </Typography>
                </Grid>
                {
                    config.fields.residents.resident.living_concerns && <Grid item xs={12} md={4}>
                        <TextField
                            label="Living concerns"
                            helperText="Detailed explanation of any present/past living concerns"
                            placeholder="Living Concerns"
                            fullWidth
                            multiline
                            rows={12}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.living_concerns}
                            name={"living_concerns"}
                            variant={'outlined'}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                <Grid item xs={12} md={8}>
                    {
                        config.fields.residents.resident.require_living_assistant && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.require_living_assistant}
                                    name={"require_living_assistant"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Living assistant requirement"
                        />
                    }
                    {
                        config.fields.residents.resident.assistant && <TextField
                            label="Living assistant"
                            helperText="The living assistant that the resident is dependent upon"
                            placeholder="Living assistant"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.assistant}
                            name={"assistant"}
                            variant={'outlined'}
                            onChange={handleChange}
                            disabled={!resident.require_living_assistant}
                        />
                    }
                    {
                        config.fields.residents.resident.reason_assistant && <TextField
                            label="Living assistant reason"
                            helperText="The purpose for using a living assistant"
                            placeholder="Living assistant reason"
                            fullWidth
                            multiline
                            rows={5}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.reason_assistant}
                            name={"reason_assistant"}
                            variant={'outlined'}
                            onChange={handleChange}
                            disabled={!resident.require_living_assistant}
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h5'}>
                        Health center information
                    </Typography>
                </Grid>
                {
                    config.fields.residents.resident.physician && <Grid item xs={12}>
                        <TextField
                            label="Physician"
                            helperText="The full name of the patient's physician"
                            placeholder="Physician's name"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.physician}
                            name={"physician"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.physician_address && <Grid item xs={12} md={6}>
                        <TextField
                            label="Physician's address"
                            helperText="The living address of the patient's physician"
                            placeholder="Physician's address"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.physician_address}
                            name={"physician_address"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.physician_telephone && <Grid item xs={12} md={6}>
                        <TextField
                            label="Physician's telephone number"
                            helperText="The contact number of the patient's physician"
                            placeholder="Physician's telephone number"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.physician_telephone}
                            name={"physician_telephone"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.hospital && <Grid item xs={12} md={8}>
                        <TextField
                            label="Hospital"
                            helperText="The physician's hospital"
                            placeholder="Physician's hospital"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.hospital}
                            name={"hospital"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.describe_state_of_health && <Grid item xs={12}>
                        <TextField
                            label="Health state"
                            helperText="Detailed description of the resident's health"
                            placeholder="Resident's health status"
                            fullWidth
                            multiline
                            rows={10}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.describe_state_of_health}
                            name={"describe_state_of_health"}
                            onChange={handleChange}
                            variant={"outlined"}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.often_doctor_visit && <Grid item xs={12} md={6}>
                        <TextField
                            label="Doctor visits"
                            helperText="The regularity of needed doctor visits"
                            placeholder="Doctor visits"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.often_doctor_visit}
                            name={"often_doctor_visit"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.last_doctor_visit && <Grid item xs={12} md={6}>
                        <DatePicker
                            label="Last doctor visit"
                            helperText="The date of the most recent doctor visit"
                            type="date"
                            format="yyyy-MM-DD"
                            value={resident.last_doctor_visit}
                            name={"last_doctor_visit"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            onChange={(date) => handleDateChange('last_doctor_visit', date)}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.walking && <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel shrink id="walking">
                                Walking ability
                            </InputLabel>
                            <Select
                                labelId="walking"
                                value={resident.walking}
                                name={"walking"}
                                onChange={handleChange}
                            >
                                <MenuItem value={"Low"}>Low</MenuItem>
                                <MenuItem value={"Moderate"}>Moderate</MenuItem>
                                <MenuItem value={"High"}>High</MenuItem>
                            </Select>
                            <FormHelperText>The resident's ability to walk</FormHelperText>
                        </FormControl>
                    </Grid>
                }
                {
                    config.fields.residents.resident.walking_instrument && <Grid item xs={12} md={6}>
                        <TextField
                            label="Walking instrument"
                            helperText="The patient's necessary aid for walking"
                            placeholder="Walking instrument"
                            value={resident.walking_instrument}
                            name={"walking_instrument"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                }
                <Grid item xs={12} md={6}>
                    {
                        config.fields.residents.resident.if_medications && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.if_medications}
                                    name={"if_medications"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Required medications"
                        />
                    }
                    {
                        config.fields.residents.resident.medications_assistance && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.medications_assistance}
                                    name={"medications_assistance"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Medical assistance required"
                        />
                    }
                    {
                        config.fields.residents.resident.medications && <TextField
                            label="Medication description"
                            helperText="Detailed explanation of any present required medications"
                            placeholder="Required medications"
                            fullWidth
                            multiline
                            rows={10}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.medications}
                            name={"medications"}
                            variant={'outlined'}
                            onChange={handleChange}
                            disabled={!resident.if_medications}
                        />
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    {
                        config.fields.residents.resident.own_meals && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.own_meals}
                                    name={"own_meals"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Prepares own meals"
                        />
                    }
                    {
                        config.fields.residents.resident.if_diet && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.if_diet}
                                    name={"if_diet"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Special diet"
                        />
                    }
                    {
                        config.fields.residents.resident.diet && <TextField
                            label="Diet description"
                            helperText="Detailed explanation of any present required special diet"
                            placeholder="Required special diet"
                            fullWidth
                            multiline
                            rows={10}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.diet}
                            name={"diet"}
                            variant={'outlined'}
                            onChange={handleChange}
                            disabled={!resident.if_diet}
                        />
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    {
                        config.fields.residents.resident.health_insurance && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.health_insurance}
                                    name={"health_insurance"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Health insurance"
                        />
                    }
                    {
                        config.fields.residents.resident.medicare_insurance && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.medicare_insurance}
                                    name={"medicare_insurance"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Medicare insurance"
                        />
                    }
                    {
                        config.fields.residents.resident.insurances && <TextField
                            label="Insurance description"
                            helperText="Detailed explanation of insurance coverage"
                            placeholder="Insurance description"
                            fullWidth
                            multiline
                            rows={5}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.insurances}
                            name={"insurances"}
                            variant={'outlined'}
                            onChange={handleChange}
                        />
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    {
                        config.fields.residents.resident.ad8_score && <TextField
                            label="AD8 score"
                            helperText="The result of the AD8 test"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type={'number'}
                            value={resident.ad8_score}
                            name={"ad8_score"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.handle_bathing && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.handle_bathing}
                                    name={"handle_bathing"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Bathing"
                        />
                    }
                    {
                        config.fields.residents.resident.handle_dressing && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.handle_dressing}
                                    name={"handle_dressing"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Dressing"
                        />
                    }
                    {
                        config.fields.residents.resident.handle_mouth_skin_care && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.handle_mouth_skin_care}
                                    name={"handle_mouth_skin_care"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Mouth skin care"
                        />
                    }
                    {
                        config.fields.residents.resident.handle_grooming && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.handle_grooming}
                                    name={"handle_grooming"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Grooming"
                        />
                    }
                    {
                        config.fields.residents.resident.handle_toileting && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.handle_toileting}
                                    name={"handle_toileting"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Toileting"
                        />
                    }
                    {
                        config.fields.residents.resident.handle_mobility && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.handle_mobility}
                                    name={"handle_mobility"}
                                    onChange={handleChecked}
                                    color="primary"
                                />
                            }
                            label="Mobile"
                        />
                    }
                    {
                        config.fields.residents.resident.handle_med_reminder && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.handle_med_reminder}
                                    name={"handle_med_reminder"}
                                    onChange={handleChecked}
                                    value={resident.handle_med_reminder}
                                    color="primary"
                                />
                            }
                            label="Medicine reminder"
                        />
                    }
                    {
                        config.fields.residents.resident.handle_night_care && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.handle_night_care}
                                    name={"handle_night_care"}
                                    onChange={handleChecked}
                                    value={resident.handle_night_care}
                                    color="primary"
                                />
                            }
                            label="Night care"
                        />
                    }
                    {
                        config.fields.residents.resident.handle_housekeeping && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.handle_housekeeping}
                                    name={"handle_housekeeping"}
                                    onChange={handleChecked}
                                    value={resident.handle_housekeeping}
                                    color="primary"
                                />
                            }
                            label="Housekeeping"
                        />
                    }
                    {
                        config.fields.residents.resident.handle_clothing && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.handle_clothing}
                                    name={"handle_clothing"}
                                    onChange={handleChecked}
                                    value={resident.handle_clothing}
                                    color="primary"
                                />
                            }
                            label="Clothing"
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h5'}>
                        Additional personal and visit information
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    {
                        config.fields.residents.resident.day_visit && <FormControlLabel
                            control={
                                <Switch
                                    checked={resident.day_visit}
                                    onChange={handleChecked}
                                    name="day_visit"
                                    color="primary"
                                />
                            }
                            label="In need of a day visit"
                        />
                    }
                    {
                        config.fields.residents.resident.reason_day_visit && <TextField
                            label="Day visit reason"
                            helperText="The purpose for a daily visit"
                            placeholder="Day visit reason"
                            fullWidth
                            multiline
                            rows={8}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.reason_day_visit}
                            name={"reason_day_visit"}
                            variant={'outlined'}
                            onChange={handleChange}
                            disabled={!resident.day_visit}
                        />
                    }
                    {
                        config.fields.residents.resident.length_day_visit && <TextField
                            label="Day visit length"
                            helperText="The length of the residents day visit"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type={'number'}
                            step={0.01}
                            value={resident.length_day_visit}
                            name={"length_day_visit"}
                            onChange={handleChange}
                            disabled={!resident.day_visit}
                        />
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    {
                        config.fields.residents.resident.place_born && <TextField
                            label="Birth place"
                            helperText="The city where the resident is born"
                            placeholder="Place of birth"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.place_born}
                            name={"place_born"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.place_childhood && <TextField
                            label="Childhood place"
                            helperText="The city where the resident spend his/her childhood"
                            placeholder="Childhood place"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.place_childhood}
                            name={"place_childhood"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.place_young_adult && <TextField
                            label="Young adult place"
                            helperText="The city where the resident spend his/her young adult years"
                            placeholder="Young adult place"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.place_young_adult}
                            name={"place_young_adult"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.place_older_adult && <TextField
                            label="Old adult place"
                            helperText="The city where the resident spend his/her older adult years"
                            placeholder="Old adult place"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.place_older_adult}
                            name={"place_older_adult"}
                            onChange={handleChange}
                        />
                    }
                </Grid>
                {
                    config.fields.residents.resident.education && <Grid item xs={12} md={6}>
                        <TextField
                            label="Education"
                            helperText="Some education information"
                            placeholder="Education"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.education}
                            name={"education"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.employment && <Grid item xs={12} md={6}>
                        <TextField
                            label="Employment"
                            helperText="Some employment information"
                            placeholder="Employment"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.employment}
                            name={"employment"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.other_interests && <Grid item xs={12}>
                        <TextField
                            label="Other Interests"
                            helperText="Additional interests the resident may have"
                            placeholder="Other Interests"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.other_interests}
                            name={"other_interests"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.vacation_travel_spots && <Grid item xs={12} md={6}>
                        <TextField
                            label="Vacation travel spots"
                            helperText="Places the resident has visited or would like to"
                            placeholder="Vacation travel spots"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.vacation_travel_spots}
                            name={"vacation_travel_spots"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.religion && <Grid item xs={12} md={6}>
                        <TextField
                            label="Religion"
                            helperText="The resident's practicing religion"
                            placeholder="Religion"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.religion}
                            name={"religion"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.military && <Grid item xs={12} md={6}>
                        <TextField
                            label="Military"
                            helperText="The resident's military background"
                            placeholder="Military"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.military}
                            name={"military"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.personal_characteristics && <Grid item xs={12}>
                        <TextField
                            label="Personal characteristics"
                            helperText="The resident's general personal characteristics"
                            placeholder="Personal characteristics"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.personal_characteristics}
                            name={"personal_characteristics"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.fields.residents.resident.other_information && <Grid item xs={12}>
                        <TextField
                            label="Other information"
                            helperText="Additional relevant information about the resident"
                            placeholder="Other information"
                            fullWidth
                            multiline
                            rows={8}
                            variant={'outlined'}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.other_information}
                            name={"other_information"}
                            onChange={handleChange}
                        />
                    </Grid>
                }
                {
                    config.sections.residents.resident.hobbies && <Grid item xs={12}>
                        <Typography variant={'h5'}>
                            Hobbies, activities and interests
                        </Typography>
                    </Grid>
                }
                <Grid item>
                    {
                        config.fields.residents.resident.listening_music && <TextField
                            label="Music preference"
                            helperText="The type of music the resident is fond of"
                            placeholder="Music Preference"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.listening_music}
                            name={"listening_music"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.singing && <TextField
                            label="Singing preference"
                            helperText="The resident's singing ability"
                            placeholder="Singing preference"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.singing}
                            name={"singing"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.playing_instruments && <TextField
                            label="Playing instrument"
                            helperText="The resident's instrument of choice"
                            placeholder="Playing instrument"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.playing_instruments}
                            name={"playing_instruments"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.dancing && <TextField
                            label="Dancing"
                            helperText="The resident's dancing ability"
                            placeholder="Dancing"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.dancing}
                            name={"dancing"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.enjoy_pets && <TextField
                            label="Pets"
                            helperText="The resident's affection towards animals"
                            placeholder="Pets"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.enjoy_pets}
                            name={"enjoy_pets"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.sports && <TextField
                            label="Sports"
                            helperText="The resident's affection towards sports"
                            placeholder="Sports"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.sports}
                            name={"sports"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.exercising && <TextField
                            label="Exercising"
                            helperText="The resident's affection towards exercising"
                            placeholder="Sports"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.exercising}
                            name={"exercising"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.reading && <TextField
                            label="Reading"
                            helperText="The resident's affection towards reading"
                            placeholder="Reading"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.reading}
                            name={"reading"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.card_games && <TextField
                            label="Card games"
                            helperText="The resident's affection towards card games"
                            placeholder="Card games"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.card_games}
                            name={"card_games"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.board_games && <TextField
                            label="Board games"
                            helperText="The resident's affection towards board games"
                            placeholder="Board games"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.board_games}
                            name={"board_games"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.drawing && <TextField
                            label="Drawing"
                            helperText="The resident's affection towards drawing"
                            placeholder="Drawing"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.drawing}
                            name={"drawing"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.knitting && <TextField
                            label="Knitting"
                            helperText="The resident's affection towards knitting"
                            placeholder="Knitting"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.knitting}
                            name={"knitting"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.sightseeing && <TextField
                            label="Sightseeing"
                            helperText="The resident's affection towards sightseeing"
                            placeholder="Sports"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.sightseeing}
                            name={"sightseeing"}
                            onChange={handleChange}
                        />
                    }
                    {
                        config.fields.residents.resident.other_activities && <TextField
                            label="Other activities"
                            helperText="Other relevant interests"
                            placeholder="Other Activities"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={resident.other_activities}
                            name={"other_activities"}
                            onChange={handleChange}
                        />
                    }
                </Grid>
                {
                    !resident_id && <Grid item xs={12}>
                        <Button color={'secondary'}
                                size={'large'}
                                variant={'contained'}
                                onClick={handleCreate}
                        >
                            Create Resident
                        </Button>
                    </Grid>
                }
                {
                    can_edit && resident_id && <Grid item xs={12}>
                        <Button color={'secondary'}
                                size={'large'}
                                variant={'contained'}
                                onClick={handleSave}
                        >
                            Save Resident
                        </Button>
                    </Grid>
                }
            </Grid>
        </>
    )
}

const mapStateToProps = store => {
    return {
        resident: store.residents.resident_details,
        guardians: store.accounts.guardians,
        medical_reports: store.residents.medical_reports
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onResidentFetch: (token, id) => dispatch(actions.fetchResident(token, id)),
        onResidentUpdate: (name, value) => dispatch(actions.updateResidentDetails(name, value)),
        onResidentSave: (token, id, data) => dispatch(actions.saveResident(token, id, data)),
        onClearResidentDetails: () => dispatch(actions.clearResidentDetails()),
        onGuardiansFetch: (token) => dispatch(actions.fetchGuardians(token)),
        onMedicalReportsFetch: (token) => dispatch(actions.fetchMedicalReports(token)),
        onResidentCreate: (token, data) => dispatch(actions.createResident(token, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResidentForm)
