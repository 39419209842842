import React from 'react';
import './App.css';
import Tasks from "../components/tasks/Tasks";
import UserForm from "../components/register/UserForm";
import Profile from "../components/auth/Profile";
import Dashboard from "../components/dashboard/Dashboard";
import {BrowserRouter, Route} from "react-router-dom";
import {Layout} from "../layout/Layout";
import Accounts from "../components/accounts/Accounts";
import Events from "../components/events/Events";
import Residents from "../components/residents/Residents";
import Medications from "../components/medications/Medications";
import GuardedRoute from "../components/auth/GuardedRoute";
import {Login} from "../components/auth/Login";
import {Logout} from "../components/auth/Logout";
import {useAuth0} from "@auth0/auth0-react";
import ResidentForm from "../components/residents/ResidentForm";
import MedicalReport from "../components/residents/MedicalReport";
import {Spinner} from "../components/shared/Spinner";
import DoctorForm from "../components/medications/DoctorForm";
import DiagnosisForm from "../components/medications/DiagnosisForm";
import {Statistics} from "../components/statistics/Statistics";

const App = () => {
    const {isAuthenticated, isLoading} = useAuth0()
    if (isLoading)
        return (
            <Layout>
                <Spinner/>
            </Layout>
        )
    return (
        <BrowserRouter>
            <Layout>
                {/*MODULES*/}
                <GuardedRoute exact path={'/tasks'} isAuthenticated={isAuthenticated} component={Tasks}/>
                <GuardedRoute exact path={'/stats'} isAuthenticated={isAuthenticated} component={Statistics}/>

                <GuardedRoute exact path={'/accounts'} isAuthenticated={isAuthenticated} component={Accounts}/>
                <GuardedRoute exact path={'/accounts/create'} isAuthenticated={isAuthenticated} component={UserForm}/>
                <GuardedRoute exact path={'/accounts/:account_type/:account_id'} isAuthenticated={isAuthenticated}
                              component={UserForm}/>

                <GuardedRoute exact path={'/events'} isAuthenticated={isAuthenticated} component={Events}/>

                <GuardedRoute exact path={'/residents'} isAuthenticated={isAuthenticated} component={Residents}/>
                <GuardedRoute exact path={'/residents/create'} isAuthenticated={isAuthenticated}
                              component={ResidentForm}/>
                <GuardedRoute exact path={'/residents/details/:resident_id'} isAuthenticated={isAuthenticated}
                              component={ResidentForm}/>

                <GuardedRoute exact path={'/medical_report/details/:report_id'}
                              isAuthenticated={isAuthenticated}
                              component={MedicalReport}/>
                <GuardedRoute exact path={'/medical_report/create'} isAuthenticated={isAuthenticated}
                              component={MedicalReport}/>

                <GuardedRoute exact path={'/diagnosis/details/:diagnosis_id'}
                              isAuthenticated={isAuthenticated} component={DiagnosisForm}/>
                <GuardedRoute exact path={'/diagnosis/create'}
                              isAuthenticated={isAuthenticated} component={DiagnosisForm}/>

                <GuardedRoute exact path={'/doctors/create'} isAuthenticated={isAuthenticated}
                              component={DoctorForm}/>
                <GuardedRoute exact path={'/doctors/details/:doctor_id'} isAuthenticated={isAuthenticated}
                              component={DoctorForm}/>

                <GuardedRoute exact path={'/medications'} isAuthenticated={isAuthenticated} component={Medications}/>

                {/*AUTH PATHS*/}
                <GuardedRoute exact path={'/profile'} isAuthenticated={isAuthenticated} component={Profile}/>
                <Route exact path={'/login'} component={Login}/>
                <Route exact path={'/logout'} component={Logout}/>

                {/*DEFAULT ROUTE*/}
                <Route exact path={'/'} component={Dashboard}/>
            </Layout>
        </BrowserRouter>
    );
}

export default App
