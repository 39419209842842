import {
    ACCOUNTS_CLEAR_ACCOUNT_DETAILS,
    ACCOUNTS_FETCH_ADMINISTRATOR,
    ACCOUNTS_FETCH_ADMINISTRATORS,
    ACCOUNTS_FETCH_DISTRICT_LEAD,
    ACCOUNTS_FETCH_DISTRICT_LEADS,
    ACCOUNTS_FETCH_EMPLOYEE,
    ACCOUNTS_FETCH_EMPLOYEES,
    ACCOUNTS_FETCH_GUARDIAN,
    ACCOUNTS_FETCH_GUARDIANS,
    ACCOUNTS_FETCH_OPERATOR,
    ACCOUNTS_FETCH_OPERATORS,
    ACCOUNTS_FETCH_USER,
    ACCOUNTS_FETCH_USERS,
    ACCOUNTS_LOADING_EMPLOYEE,
    ACCOUNTS_LOADING_GUARDIAN,
    ACCOUNTS_UPDATE_EMPLOYEE_DETAILS,
    ACCOUNTS_UPDATE_GUARDIAN_DETAILS
} from "../actions/action.types";
import {DefaultEmployee, DefaultGuardian} from "../../components/shared/Constants";

const initialState = {}

export const accountsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNTS_FETCH_ADMINISTRATOR:
            return {
                ...state,
                administrator_details: action.payload
            }
        case ACCOUNTS_FETCH_ADMINISTRATORS:
            return {
                ...state,
                administrators: action.payload
            }
        case ACCOUNTS_FETCH_DISTRICT_LEAD:
            return {
                ...state,
                district_lead_details: action.payload
            }
        case ACCOUNTS_FETCH_DISTRICT_LEADS:
            return {
                ...state,
                district_leads: action.payload
            }
        case ACCOUNTS_LOADING_GUARDIAN: {
            return {
                ...state,
                loading_accounts_details: true,
                guardian_details: undefined
            }
        }
        case ACCOUNTS_FETCH_GUARDIAN:
            return {
                ...state,
                loading_accounts_details: false,
                guardian_details: action.payload
            }
        case ACCOUNTS_FETCH_GUARDIANS:
            return {
                ...state,
                guardians: action.payload
            }
        case ACCOUNTS_FETCH_OPERATOR:
            return {
                ...state,
                operator_details: action.payload
            }
        case ACCOUNTS_FETCH_OPERATORS:
            return {
                ...state,
                operators: action.payload
            }
        case ACCOUNTS_FETCH_USER:
            return {
                ...state,
                user_details: action.payload
            }
        case ACCOUNTS_FETCH_USERS:
            return {
                ...state,
                users: action.payload
            }
        case ACCOUNTS_LOADING_EMPLOYEE: {
            return {
                ...state,
                loading_accounts_details: true,
                employee_details: undefined
            }
        }
        case ACCOUNTS_FETCH_EMPLOYEE:
            return {
                ...state,
                employee_details: action.payload,
                loading_employee_details: false
            }
        case ACCOUNTS_FETCH_EMPLOYEES:
            return {
                ...state,
                employees: action.payload
            }
        case ACCOUNTS_UPDATE_EMPLOYEE_DETAILS: {
            let newState;
            action.payload.isAuth ? newState = {
                ...state,
                employee_details: {
                    ...state.employee_details,
                    auth_user: {
                        ...state.employee_details.auth_user,
                        [action.payload.name]: action.payload.value
                    }
                }
            } : newState = {
                ...state,
                employee_details: {
                    ...state.employee_details,
                    [action.payload.name]: action.payload.value
                }
            }
            return newState
        }
        case ACCOUNTS_UPDATE_GUARDIAN_DETAILS: {
            let newState;
            action.payload.isAuth ? newState = {
                ...state,
                guardian_details: {
                    ...state.guardian_details,
                    auth_user: {
                        ...state.guardian_details.auth_user,
                        [action.payload.name]: action.payload.value
                    }
                }
            } : newState = {
                ...state,
                guardian_details: {
                    ...state.guardian_details,
                    [action.payload.name]: action.payload.value
                }
            }
            return newState
        }
        case ACCOUNTS_CLEAR_ACCOUNT_DETAILS:
            return {
                ...state,
                guardian_details: {
                    ...DefaultGuardian
                },
                employee_details: {
                    ...DefaultEmployee
                }
            }
        default:
            return {
                ...state
            }
    }
}
