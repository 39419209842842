import React, {useEffect, useState} from "react";
import {AccountsListCard} from "./AccountsListCard";
import * as actions from '../../store/actions'
import {useAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/styles";
import {AccountsHeader} from "./AccountsHeader";
import {useHistory} from "react-router";
import {Spinner} from "../shared/Spinner";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";

const styles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    cards: {
        marginTop: theme.spacing(3),
    }
}))

const Accounts = (props) => {
    const classes = styles()
    const history = useHistory()
    const {getAccessTokenSilently} = useAuth0();
    const [state, set_state] = useState({
        fetchEmployees: false,
        fetchGuardians: true
    });
    useEffect(() => {
        getAccessTokenSilently().then(token => {
            props.onEmployeesFetch(token);
            props.onGuardiansFetch(token);
        })
    }, [])
    if (!props.employees || !props.guardians)
        return <Spinner/>

    const buttonCallback = () => {
        history.push('/accounts/create');
    }

    const handleChange = (event) => {
        const {name, checked} = event.target
        set_state({...state, [name]: checked})
    }

    return (
        <div className={classes.root}>
            <AccountsHeader callback={buttonCallback}/>
            <FormControl component="fieldset">
                <FormLabel component="legend">Select user types to display</FormLabel>
                <FormGroup row>
                    <FormControlLabel
                        control={<Checkbox name={'fetchEmployees'}
                                           checked={state.fetchEmployees}
                                           onChange={handleChange}
                                           color={"primary"}/>}
                        label={'Employees'}
                    />
                    <FormControlLabel
                        control={<Checkbox name={'fetchGuardians'}
                                           checked={state.fetchGuardians}
                                           onChange={e => set_state({...state, [e.target.name]: e.target.checked})}
                                           color={"primary"}/>}
                        label={'Guardians'}
                    />
                </FormGroup>
            </FormControl>
            <div className={classes.cards}>
                {
                    state.fetchEmployees && props.employees.map(emp => <AccountsListCard key={emp.id}
                                                                                         account={emp}
                                                                                         account_type={'employee'}/>)
                }
                {
                    state.fetchGuardians && props.guardians.map(emp => <AccountsListCard key={emp.id}
                                                                                         account={emp}
                                                                                         account_type={'guardian'}/>)
                }
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onEmployeesFetch: (token) => dispatch(actions.fetchEmployees(token)),
        onGuardiansFetch: (token) => dispatch(actions.fetchGuardians(token))
    }
}

const mapStateToProps = store => {
    return {
        employees: store.accounts.employees,
        guardians: store.accounts.guardians
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
