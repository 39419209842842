import {
    MEDICATIONS_CLEAR_DIAGNOSIS_DETAILS,
    MEDICATIONS_CLEAR_DOCTOR_DETAILS,
    MEDICATIONS_FETCH_ALL_DIAGNOSIS,
    MEDICATIONS_FETCH_DIAGNOSIS,
    MEDICATIONS_FETCH_DOCTOR,
    MEDICATIONS_FETCH_DOCTORS,
    MEDICATIONS_FETCH_MEDICAL_EXAMINATION,
    MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPE,
    MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPES,
    MEDICATIONS_FETCH_MEDICAL_EXAMINATIONS,
    MEDICATIONS_FETCH_MEDICATION,
    MEDICATIONS_FETCH_MEDICATION_SCHEDULE,
    MEDICATIONS_FETCH_MEDICATION_SCHEDULES,
    MEDICATIONS_FETCH_MEDICATIONS,
    MEDICATIONS_FETCH_THERAPIES,
    MEDICATIONS_FETCH_THERAPY,
    MEDICATIONS_FETCH_THERAPY_SCHEDULE,
    MEDICATIONS_FETCH_THERAPY_SCHEDULES,
    MEDICATIONS_UPDATE_DIAGNOSIS_DETAILS,
    MEDICATIONS_UPDATE_DOCTOR_DETAILS
} from '../actions/action.types'
import {DefaultDiagnosis, DefaultDoctor} from "../../components/shared/Constants";

const initialState = {}

export const medicationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case MEDICATIONS_FETCH_ALL_DIAGNOSIS:
            return {
                ...state,
                diagnosis: action.payload
            }
        case MEDICATIONS_FETCH_DIAGNOSIS:
            return {
                ...state,
                diagnosis_details: action.payload
            }
        case MEDICATIONS_FETCH_DOCTOR:
            return {
                ...state,
                doctor_details: action.payload
            }
        case MEDICATIONS_FETCH_DOCTORS:
            return {
                ...state,
                doctors: action.payload
            }
        case MEDICATIONS_FETCH_MEDICAL_EXAMINATION:
            return {
                ...state,
                medical_examination_details: action.payload
            }
        case MEDICATIONS_FETCH_MEDICAL_EXAMINATIONS:
            return {
                ...state,
                medical_examinations: action.payload
            }
        case MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPE:
            return {
                ...state,
                medical_examination_type_details: action.payload
            }
        case MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPES:
            return {
                ...state,
                medical_examination_types: action.payload
            }
        case MEDICATIONS_FETCH_MEDICATION:
            return {
                ...state,
                medication_details: action.payload
            }
        case MEDICATIONS_FETCH_MEDICATIONS:
            return {
                ...state,
                medications: action.payload
            }
        case MEDICATIONS_FETCH_MEDICATION_SCHEDULE:
            return {
                ...state,
                medication_schedule_details: action.payload
            }
        case MEDICATIONS_FETCH_MEDICATION_SCHEDULES:
            return {
                ...state,
                medication_schedules: action.payload
            }
        case MEDICATIONS_FETCH_THERAPIES:
            return {
                ...state,
                therapies: action.payload
            }
        case MEDICATIONS_FETCH_THERAPY:
            return {
                ...state,
                therapy_details: action.payload
            }
        case MEDICATIONS_FETCH_THERAPY_SCHEDULE:
            return {
                ...state,
                therapy_schedule_details: action.payload
            }
        case MEDICATIONS_FETCH_THERAPY_SCHEDULES:
            return {
                ...state,
                therapy_schedules: action.payload
            }
        case MEDICATIONS_UPDATE_DOCTOR_DETAILS: {
            return {
                ...state,
                doctor_details: {
                    ...state.doctor_details,
                    [action.payload.name]: action.payload.value
                }
            }
        }
        case MEDICATIONS_CLEAR_DOCTOR_DETAILS: {
            return {
                ...state,
                doctor_details: {
                    ...DefaultDoctor
                }
            }
        }
        case MEDICATIONS_UPDATE_DIAGNOSIS_DETAILS: {
            return {
                ...state,
                diagnosis_details: {
                    ...state.diagnosis_details,
                    [action.payload.name]: action.payload.value
                }
            }
        }
        case MEDICATIONS_CLEAR_DIAGNOSIS_DETAILS: {
            return {
                ...state,
                diagnosis_details: {
                    ...DefaultDiagnosis
                }
            }
        }
        default:
            return {
                ...state
            }
    }
}
