import {authorized} from '../../axios'
import {AUTH_REGISTER_USER} from "./action.types";
import {formatCreateUserData} from "../../components/shared/utils";


export const registerUser = (token, data) => dispatch => {
    let user = formatCreateUserData(data)
    authorized(token)
        .post('accounts/api/v1/register/', {
            ...user
        })
        .then(resp => {
            dispatch({
                type: AUTH_REGISTER_USER,
                payload: resp.data
            })
            alert('Success')
        })
        .catch(alert)
}

export const saveAccount = (token, id, type, data) => dispatch => {
    let url = `accounts/api/v1/${type}/${id}/`
    authorized(token)
        .put(url, data)
        .then(resp => alert('Success'))
        .catch(alert)
}
