import React from "react";

const Medications = (props) => {
    return (
        <div>
            Medications works!
        </div>
    )
}

export default Medications
