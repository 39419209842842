import React from "react";
import {MyCalendar} from "./Calendar";
import {makeStyles} from "@material-ui/styles";
import {Typography} from "@material-ui/core";

const useStyle = makeStyles(theme => ({
    header: {
        marginBottom: theme.spacing(7),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
}))

const Events = (props) => {
    const classes = useStyle();

    return (
        <>
            <div className={classes.header}>
                <div>
                    <Typography variant={"h3"}>Events</Typography>
                    <Typography variant={"body1"}>Easily navigate through your events</Typography>
                </div>
            </div>
            <MyCalendar/>
        </>
    )
}

export default Events
